import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as visitorService from 'services/sales';
import {
  DEFAULT_BOOKING_APPROVAL_FILTERS,
  DEFAULT_USER_FILTER,
  processError,
} from 'utils/constant';
import { handleLoading, handleReject } from 'utils/reduxUtils';

import {
  AddBrokerParams,
  bookingApprovalListParams,
  bookingApprovedRejectParams,
  brokerDetailParams,
  CLPInfoParams,
  CLPListParams,
  CLPPercentageData,
  CommonParams,
  CreateCustomerParams,
  FormFillingParams,
  getApprovalDetailsParams,
  getEmployeeWiseSalesParams,
  getFollowUpListParams,
  getLeadsPercentBySourceParams,
  GetProjectUnitParams,
  getSalesInquiryFlowParams,
  getSalesUserParams,
  getSoldUnitsByMonthsParams,
  getSoldUnitsCountParams,
  IBookingFormApproval,
  IBookingFormParams,
  IBookingOTPs,
  IBookingOwnershipFlag,
  IBroker,
  IBrokerDetail,
  ICLPInstallmentDetails,
  ICLPInstallmentOptions,
  ICountryCodeOption,
  IEmployeeWiseSalesData,
  IExtraCharges,
  IFollowUpCount,
  IFollowUpList,
  IInquiryOwnerships,
  IInstallmentDetails,
  IInstallmentOptions,
  ILeadFlow,
  ILeadsByPriority,
  ILeadsBySource,
  InstallmentParams,
  IOtherCharges,
  IOtherChargesParam,
  IOwnerShipInputField,
  IParkingList,
  IPropertySoldByCategory,
  ISalesInquiryFlowData,
  ISalesPipeline,
  ISalesState,
  ISoldUnitsByMonths,
  ISoldUnitsCountBySource,
  ITermsnConditions,
  ITotalProperties,
  ITowers,
  IUnitAreaInfo,
  IUnitAreaInfoParam,
  IUnitInfo,
  IUnitParkingInfo,
  IUnitStatus,
  IUserOption,
  IVisitor,
  IVisitorDetail,
  IVisitorYearOption,
  ParkingParams,
  SendBookingOTPParams,
  UnitInfoParams,
  visitorDetailParams,
  VisitorParams,
} from './salesInterface';

export const getVisitorsList = createAsyncThunk<IVisitor[], VisitorParams>(
  'sales/getVisitorsList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getVisitorsList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getInquiryOwnerships = createAsyncThunk<IInquiryOwnerships, visitorDetailParams>(
  'sales/getInquiryOwnerships',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getInquiryOwnerships(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getCustomersList = createAsyncThunk<IVisitor[], CommonParams>(
  'sales/getCustomersList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getCustomersList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getVisitorsDetail = createAsyncThunk<IVisitorDetail, visitorDetailParams>(
  'sales/getVisitorsDetails',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getVisitorDetail(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getBrokerList = createAsyncThunk<IBroker[], CommonParams>(
  'sales/getBrokerList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getBrokerList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getBrokerDetail = createAsyncThunk<IBrokerDetail, brokerDetailParams>(
  'sales/getBrokerDetail',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getBrokerDetail(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getApprovalUnitDetails = createAsyncThunk<
  IBookingFormApproval,
  getApprovalDetailsParams
>('sales/getApprovalUnitDetails', async (params, thunkApi) => {
  try {
    const { data: res } = await visitorService.getApprovalUnitDetails(params);
    return res.data;
  } catch (err) {
    const processedError = processError(err);
    console.log(err);
    return thunkApi.rejectWithValue({ error: processedError });
  }
});

export const getBookingApprovalList = createAsyncThunk(
  'sales/getBookingApprovalList',
  async (params: bookingApprovalListParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.getBookingApprovalList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const updateBookingStatus = createAsyncThunk(
  'sales/updateBookingStatus',
  async (params: bookingApprovedRejectParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.updateBookingStatus(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const updateUnitStatusToAvailable = createAsyncThunk(
  'sales/updateUnitStatusToAvailable',
  async (params: FormFillingParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.updateUnitStatusToAvailable(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const addCustomer = createAsyncThunk(
  'sales/addCustomer',
  async (params: CreateCustomerParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.addCustomer(params);
      toast.success(res.msg);
      return res;
    } catch (err) {
      const processedError = processError(err);
      toast.error(Object?.values(err.data.msg).join(', '));
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const addBroker = createAsyncThunk(
  'sales/addBroker',
  async (params: AddBrokerParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.addBroker(params);
      toast.success(res.msg);
      return res;
    } catch (err) {
      const processedError = processError(err);
      toast.error(Object?.values(err.data.msg).join(', '));
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getUnitInfo = createAsyncThunk<IUnitInfo, UnitInfoParams>(
  'sales/unitInfo',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getUnitInfo(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getAreaInfo = createAsyncThunk<IUnitAreaInfo, IUnitAreaInfoParam>(
  'sales/unitAreaInfo',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getAreaInfo(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getUnitParkingInfo = createAsyncThunk<IUnitParkingInfo, CommonParams>(
  'sales/unitParkingInfo',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getUnitParkingInfo(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const addBooking = createAsyncThunk(
  'sales/addBooking',
  async (params: IBookingFormParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.addBooking(params);
      toast.success(res.msg);
      return res;
    } catch (err) {
      const processedError = processError(err);
      toast.error(Object?.values(err.data.msg).join(', '));
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getUnitBookingFormLIst = createAsyncThunk(
  'sales/addBooking',
  async (params: IBookingFormParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.getApprovalUnitDetails(params);
      return res;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const updateFormFillingStatus = createAsyncThunk(
  'sales/updateFormFillingStatus',
  async (params: FormFillingParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.updateFormFillingStatus(params);
      return res;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getOtherChargesList = createAsyncThunk<IOtherCharges, IOtherChargesParam>(
  'sales/getOtherChargesList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getOtherCharges(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getOtherExtraCharges = createAsyncThunk<IExtraCharges, IOtherChargesParam>(
  'sales/getOtherExtraCharges',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getOtherExtraCharges(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getTermsnConditions = createAsyncThunk<ITermsnConditions[], CommonParams>(
  'sales/getTerms&Conditions',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getTermsnContions(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getInstallmentOptions = createAsyncThunk<IInstallmentOptions, CommonParams>(
  'sales/getInstallmentOptions',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getInstallmentOptions(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getInstallmentDetails = createAsyncThunk<IInstallmentDetails, InstallmentParams>(
  'sales/getInstallmentDetails',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getInstallmentData(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getCLPInstallmentOptions = createAsyncThunk<ICLPInstallmentOptions[], CLPListParams>(
  'sales/getCLPInstallmentOptions',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getCLPInstallmentOptions(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getCLPInstallmentData = createAsyncThunk<ICLPInstallmentDetails[], CLPInfoParams>(
  'sales/getCLPInstallmentData',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getCLPInstallmentData(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getProjectUnitStatus = createAsyncThunk<IUnitStatus[], GetProjectUnitParams>(
  'sales/projectUnitStatus',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getProjectUnitStatus(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getBookingFormOwnerFlag = createAsyncThunk<IBookingOwnershipFlag, CommonParams>(
  'sales/getBookingFormOwnerFlag',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getBookingFormOwnerFlag(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const sendBookingFormOTP = createAsyncThunk(
  'sales/sendBookingFormOTP',
  async (params: SendBookingOTPParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.sendBookingFormOTP(params);
      toast.success(res.msg);
      return res.otp;
    } catch (err) {
      const processedError = processError(err);
      toast.error(err.data.msg);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const clpPercentageSelection = createAsyncThunk<CLPPercentageData[], CommonParams>(
  'sales/clpPercentageSelection',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.clpPercentageSelection(params);
      return res?.data;
    } catch (err) {
      const processedError = processError(err);
      // toast.error(Object?.values(err.data.msg).join(', '));
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getBankList = createAsyncThunk('sales/getBankList', async () => {
  try {
    const { data: res } = await visitorService.getBankList();
    return res.data;
  } catch (err) {
    console.log(err);
  }
});
export const getLeadFlow = createAsyncThunk<ILeadFlow, CommonParams>(
  'sales/getLeadFlow',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getLeadFlow(params);
      return res?.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getFollowUpCount = createAsyncThunk<IFollowUpCount, getSalesUserParams>(
  'sales/getFollowUpCount',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getFollowUpCount(params);
      return res?.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getFollowUpList = createAsyncThunk<IFollowUpList[], getFollowUpListParams>(
  'sales/getFollowUpList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getFollowUpList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getSalesPipeline = createAsyncThunk<ISalesPipeline, getSalesUserParams>(
  'sales/getSalesPipeline',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getSalesPipeline(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getLeadsByPriority = createAsyncThunk<ILeadsByPriority, getSalesUserParams>(
  'sales/getLeadsByPriority',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getLeadsByPriority(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getLeadsBySource = createAsyncThunk<ILeadsBySource, getLeadsPercentBySourceParams>(
  'sales/getLeadsBySource',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getLeadsBySource(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getTotalProperties = createAsyncThunk<ITotalProperties, CommonParams>(
  'sales/getTotalProperties',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getTotalProperties(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getTowers = createAsyncThunk<ITowers[], CommonParams>(
  'sales/getTowers',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getTowers(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getSoldUnitsByMonths = createAsyncThunk<
  ISoldUnitsByMonths,
  getSoldUnitsByMonthsParams
>('sales/getSoldUnitsByMonths', async (params, thunkApi) => {
  try {
    const { data: res } = await visitorService.getSoldUnitsByMonths(params);
    return res.data;
  } catch (err) {
    const processedError = processError(err);
    console.log(err);
    return thunkApi.rejectWithValue({ error: processedError });
  }
});
export const getSoldUnitsCountBySource = createAsyncThunk<
  ISoldUnitsCountBySource[],
  getSoldUnitsCountParams
>('sales/getSoldUnitsCountBySource', async (params, thunkApi) => {
  try {
    const { data: res } = await visitorService.getSoldUnitsCountBySource(params);
    return res.data;
  } catch (err) {
    const processedError = processError(err);
    console.log(err);
    return thunkApi.rejectWithValue({ error: processedError });
  }
});
export const getPropertySoldByCategory = createAsyncThunk<IPropertySoldByCategory, CommonParams>(
  'sales/getPropertySoldByCategory',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getPropertySoldByCategory(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getSalesInquiryFlow = createAsyncThunk<
  ISalesInquiryFlowData[],
  getSalesInquiryFlowParams
>('sales/getSalesInquiryFlow', async (params, thunkApi) => {
  try {
    const { data: res } = await visitorService.getSalesInquiryFlow(params);
    return res.data;
  } catch (err) {
    const processedError = processError(err);
    console.log(err);
    return thunkApi.rejectWithValue({ error: processedError });
  }
});
export const getVisitorYearOption = createAsyncThunk<IVisitorYearOption[], CommonParams>(
  'sales/getVisitorYearOption',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getVisitorYearOption(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getEmployeeWiseSales = createAsyncThunk<
  IEmployeeWiseSalesData[],
  getEmployeeWiseSalesParams
>('sales/getEmployeeWiseSales', async (params, thunkApi) => {
  try {
    const { data: res } = await visitorService.getEmployeeWiseSales(params);
    return res.data;
  } catch (err) {
    const processedError = processError(err);
    console.log(err);
    return thunkApi.rejectWithValue({ error: processedError });
  }
});
export const countryCode = createAsyncThunk<ICountryCodeOption[], CommonParams>(
  'sales/countryCode',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.countryCode(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getUserRoleOption = createAsyncThunk<IUserOption[], CommonParams>(
  'sales/getUserRoleOption',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getUserRoleOption(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const parkingList = createAsyncThunk<IParkingList[], ParkingParams>(
  'sales/parkingList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.parkingList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getOwnershipInputField = createAsyncThunk<IOwnerShipInputField, CommonParams>(
  'sales/getOwnershipInputField',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getOwnershipInputField(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
const initialState: ISalesState = {
  loading: false,
  visitorList: [],
  customerList: [],
  brokerList: [],
  unitInfo: {} as IUnitInfo,
  unitParkingInfo: {} as IUnitParkingInfo,
  otherChargesList: {} as IOtherCharges,
  termsList: [],
  installmentsList: {} as IInstallmentOptions,
  installmentsInformation: {} as IInstallmentDetails,
  CLPInstallmentList: [],
  CLPInstallmentsInformation: [],
  banksList: [],
  unitAreaInfo: {} as IUnitAreaInfo,
  extraChargesList: {} as IExtraCharges,
  projectUnitStatus: [],
  visitorDetail: {} as IVisitorDetail,
  brokerDetail: undefined,
  bookingApprovalList: undefined,
  approvalBookingDetails: {} as IBookingFormApproval,
  booking_validation: undefined,
  booking_approval_filter: DEFAULT_BOOKING_APPROVAL_FILTERS,
  booking_otp: {} as IBookingOTPs,
  clp_percentage: [],
  getSalesLeadData: {} as ILeadFlow,
  getFollowUpCountData: {} as IFollowUpCount,
  followUpData: [],
  salesPipelineData: {} as ISalesPipeline,
  leadsByPriorityData: {} as ILeadsByPriority,
  leadsBySourceData: {} as ILeadsBySource,
  getTotalPropertiesData: {} as ITotalProperties,
  getTowersData: [],
  getSoldUnitsByMonthsData: {} as ISoldUnitsByMonths,
  getSoldUnitsCountBySourceData: [],
  getPropertySoldByCategoryData: {} as IPropertySoldByCategory,
  getSalesInquiryFlowData: [],
  getVisitorYearOptionData: [],
  getEmployeeWiseSalesData: [],
  inquiryOwnerships: {} as IInquiryOwnerships,
  countryCodeOption: [],
  userInfoOption: [],
  userFilter: DEFAULT_USER_FILTER,
  parkingInfo: [],
  ownership_validation: undefined,
};

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    updateApprovalFilters: (state, action) => {
      return {
        ...state,
        booking_approval_filter: action.payload,
      };
    },
    userFilterOption: (state, action) => {
      return {
        ...state,
        userFilter: action.payload,
      };
    },
  },
  extraReducers: builder => {
    // visitors list
    builder.addCase(getVisitorsList.rejected, handleReject);
    builder.addCase(getVisitorsList.pending, handleLoading);
    builder.addCase(getVisitorsList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        visitorList: action?.payload,
      };
    });
    // customers list
    builder.addCase(getCustomersList.rejected, handleReject);
    builder.addCase(getCustomersList.pending, handleLoading);
    builder.addCase(getCustomersList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        customerList: action?.payload,
      };
    });
    // broker list
    builder.addCase(getBrokerList.rejected, handleReject);
    builder.addCase(getBrokerList.pending, handleLoading);
    builder.addCase(getBrokerList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        brokerList: action?.payload,
      };
    });
    // add visitor
    builder.addCase(addCustomer.rejected, handleReject);
    builder.addCase(addCustomer.pending, handleLoading);
    builder.addCase(addCustomer.fulfilled, state => {
      return {
        ...state,
        loading: false,
      };
    });
    // add broker
    builder.addCase(addBroker.rejected, handleReject);
    builder.addCase(addBroker.pending, handleLoading);
    builder.addCase(addBroker.fulfilled, state => {
      return {
        ...state,
        loading: false,
      };
    });
    // update form filling status
    builder.addCase(updateFormFillingStatus.rejected, handleReject);
    builder.addCase(updateFormFillingStatus.pending, handleLoading);
    builder.addCase(updateFormFillingStatus.fulfilled, state => {
      return {
        ...state,
        loading: false,
      };
    });
    // Unit info
    builder.addCase(getUnitInfo.rejected, handleReject);
    builder.addCase(getUnitInfo.pending, handleLoading);
    builder.addCase(getUnitInfo.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        unitInfo: action?.payload,
      };
    });
    // Unit Area info
    builder.addCase(getAreaInfo.rejected, handleReject);
    builder.addCase(getAreaInfo.pending, handleLoading);
    builder.addCase(getAreaInfo.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        unitAreaInfo: action?.payload,
      };
    });
    // Project Unit Status
    builder.addCase(getProjectUnitStatus.rejected, handleReject);
    builder.addCase(getProjectUnitStatus.pending, handleLoading);
    builder.addCase(getProjectUnitStatus.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        projectUnitStatus: action?.payload,
      };
    });
    // Unit Parking info
    builder.addCase(getUnitParkingInfo.rejected, handleReject);
    builder.addCase(getUnitParkingInfo.pending, handleLoading);
    builder.addCase(getUnitParkingInfo.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        unitParkingInfo: action?.payload,
      };
    });
    // Booking Form
    builder.addCase(addBooking.rejected, handleReject);
    builder.addCase(addBooking.pending, handleLoading);
    builder.addCase(addBooking.fulfilled, state => {
      return {
        ...state,
        timer: false,
        loading: false,
      };
    });
    // Booking Form Edit
    // builder.addCase(editBooking.rejected, handleReject);
    // builder.addCase(editBooking.pending, handleLoading);
    // builder.addCase(editBooking.fulfilled, state => {
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // });
    // get other charges
    builder.addCase(getOtherChargesList.rejected, handleReject);
    builder.addCase(getOtherChargesList.pending, handleLoading);
    builder.addCase(getOtherChargesList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        otherChargesList: action?.payload,
      };
    });
    // get Extra charges
    builder.addCase(getOtherExtraCharges.rejected, handleReject);
    builder.addCase(getOtherExtraCharges.pending, handleLoading);
    builder.addCase(getOtherExtraCharges.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        extraChargesList: action?.payload,
      };
    });
    // get terms and conditions
    builder.addCase(getTermsnConditions.rejected, handleReject);
    builder.addCase(getTermsnConditions.pending, handleLoading);
    builder.addCase(getTermsnConditions.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        termsList: action?.payload,
      };
    });
    // get installments options
    builder.addCase(getInstallmentOptions.rejected, handleReject);
    builder.addCase(getInstallmentOptions.pending, handleLoading);
    builder.addCase(getInstallmentOptions.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        installmentsList: action?.payload,
      };
    });
    // get installments details
    builder.addCase(getInstallmentDetails.rejected, handleReject);
    builder.addCase(getInstallmentDetails.pending, handleLoading);
    builder.addCase(getInstallmentDetails.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        installmentsInformation: action?.payload,
      };
    });
    // get CLP installments options
    builder.addCase(getCLPInstallmentOptions.rejected, handleReject);
    builder.addCase(getCLPInstallmentOptions.pending, handleLoading);
    builder.addCase(getCLPInstallmentOptions.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        CLPInstallmentList: action?.payload,
      };
    });
    // get CLP installments details
    builder.addCase(getCLPInstallmentData.rejected, handleReject);
    builder.addCase(getCLPInstallmentData.pending, handleLoading);
    builder.addCase(getCLPInstallmentData.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        CLPInstallmentsInformation: action?.payload?.sort((a, b) => a.id - b.id),
      };
    });
    // get banks List
    builder.addCase(getBankList.rejected, handleReject);
    builder.addCase(getBankList.pending, handleLoading);
    builder.addCase(getBankList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        banksList: action?.payload,
      };
    });
    // get Visitor details
    builder.addCase(getVisitorsDetail.rejected, handleReject);
    builder.addCase(getVisitorsDetail.pending, handleLoading);
    builder.addCase(getVisitorsDetail.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        visitorDetail: action?.payload,
      };
    });
    // get broker details
    builder.addCase(getBrokerDetail.rejected, handleReject);
    builder.addCase(getBrokerDetail.pending, handleLoading);
    builder.addCase(getBrokerDetail.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        brokerDetail: action?.payload,
      };
    });
    // get approval list
    builder.addCase(getBookingApprovalList.rejected, handleReject);
    builder.addCase(getBookingApprovalList.pending, handleLoading);
    builder.addCase(getBookingApprovalList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        bookingApprovalList: action?.payload.booking_form_list?.sort(
          (a, b) => b.bookingid - a.bookingid,
        ),
      };
    });
    // get approval booking details
    builder.addCase(getApprovalUnitDetails.rejected, handleReject);
    builder.addCase(getApprovalUnitDetails.pending, handleLoading);
    builder.addCase(getApprovalUnitDetails.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        approvalBookingDetails: action?.payload,
      };
    });
    // booking approve reject
    builder.addCase(updateBookingStatus.rejected, handleReject);
    builder.addCase(updateBookingStatus.pending, handleLoading);
    builder.addCase(updateBookingStatus.fulfilled, state => {
      return {
        ...state,
        loading: false,
      };
    });
    // ownership validation flag
    builder.addCase(getBookingFormOwnerFlag.rejected, handleReject);
    builder.addCase(getBookingFormOwnerFlag.pending, handleLoading);
    builder.addCase(getBookingFormOwnerFlag.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        booking_validation: action?.payload,
      };
    });
    // send booking form OTP
    builder.addCase(sendBookingFormOTP.rejected, handleReject);
    builder.addCase(sendBookingFormOTP.pending, handleLoading);
    builder.addCase(sendBookingFormOTP.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        booking_otp: action?.payload,
      };
    });
    // percentage classification options
    builder.addCase(clpPercentageSelection.rejected, handleReject);
    builder.addCase(clpPercentageSelection.pending, handleLoading);
    builder.addCase(clpPercentageSelection.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        clp_percentage: action?.payload,
      };
    });
    // Country code Option
    builder.addCase(countryCode.rejected, handleReject);
    builder.addCase(countryCode.pending, handleLoading);
    builder.addCase(countryCode.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        countryCodeOption: action?.payload,
      };
    });

    // Parking List
    builder.addCase(parkingList.rejected, handleReject);
    builder.addCase(parkingList.pending, handleLoading);
    builder.addCase(parkingList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        parkingInfo: action?.payload,
      };
    });

    // Sales Dashboard
    // Total Leads
    builder.addCase(getLeadFlow.rejected, handleReject);
    builder.addCase(getLeadFlow.pending, handleLoading);
    builder.addCase(getLeadFlow.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getSalesLeadData: action?.payload,
      };
    });
    // Follow up count
    builder.addCase(getFollowUpCount.rejected, handleReject);
    builder.addCase(getFollowUpCount.pending, handleLoading);
    builder.addCase(getFollowUpCount.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getFollowUpCountData: action?.payload,
      };
    });
    // Follow up List
    builder.addCase(getFollowUpList.rejected, handleReject);
    builder.addCase(getFollowUpList.pending, handleLoading);
    builder.addCase(getFollowUpList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        followUpData: action?.payload,
      };
    });
    // Sales Pipeline
    builder.addCase(getSalesPipeline.rejected, handleReject);
    builder.addCase(getSalesPipeline.pending, handleLoading);
    builder.addCase(getSalesPipeline.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        salesPipelineData: action?.payload,
      };
    });
    // Leads By Priority
    builder.addCase(getLeadsByPriority.rejected, handleReject);
    builder.addCase(getLeadsByPriority.pending, handleLoading);
    builder.addCase(getLeadsByPriority.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        leadsByPriorityData: action?.payload,
      };
    });
    // Leads By Source
    builder.addCase(getLeadsBySource.rejected, handleReject);
    builder.addCase(getLeadsBySource.pending, handleLoading);
    builder.addCase(getLeadsBySource.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        leadsBySourceData: action?.payload,
      };
    });
    // Total Properties
    builder.addCase(getTotalProperties.rejected, handleReject);
    builder.addCase(getTotalProperties.pending, handleLoading);
    builder.addCase(getTotalProperties.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getTotalPropertiesData: action?.payload,
      };
    });
    // Towers
    builder.addCase(getTowers.rejected, handleReject);
    builder.addCase(getTowers.pending, handleLoading);
    builder.addCase(getTowers.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getTowersData: action?.payload,
      };
    });
    // Sold Units By Months
    builder.addCase(getSoldUnitsByMonths.rejected, handleReject);
    builder.addCase(getSoldUnitsByMonths.pending, handleLoading);
    builder.addCase(getSoldUnitsByMonths.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getSoldUnitsByMonthsData: action?.payload,
      };
    });
    // Sold Units Count By Source
    builder.addCase(getSoldUnitsCountBySource.rejected, handleReject);
    builder.addCase(getSoldUnitsCountBySource.pending, handleLoading);
    builder.addCase(getSoldUnitsCountBySource.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getSoldUnitsCountBySourceData: action?.payload,
      };
    });
    // Property Sold By Category
    builder.addCase(getPropertySoldByCategory.rejected, handleReject);
    builder.addCase(getPropertySoldByCategory.pending, handleLoading);
    builder.addCase(getPropertySoldByCategory.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getPropertySoldByCategoryData: action?.payload,
      };
    });
    // Inquiry Flow
    builder.addCase(getSalesInquiryFlow.rejected, handleReject);
    builder.addCase(getSalesInquiryFlow.pending, handleLoading);
    builder.addCase(getSalesInquiryFlow.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getSalesInquiryFlowData: action?.payload,
      };
    });
    // Visitor Year Option
    builder.addCase(getVisitorYearOption.rejected, handleReject);
    builder.addCase(getVisitorYearOption.pending, handleLoading);
    builder.addCase(getVisitorYearOption.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getVisitorYearOptionData: action?.payload,
      };
    });
    // Employee wise sales
    builder.addCase(getEmployeeWiseSales.rejected, handleReject);
    builder.addCase(getEmployeeWiseSales.pending, handleLoading);
    builder.addCase(getEmployeeWiseSales.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getEmployeeWiseSalesData: action?.payload,
      };
    });
    // ownerships from events inquiry
    builder.addCase(getInquiryOwnerships.rejected, handleReject);
    builder.addCase(getInquiryOwnerships.pending, handleLoading);
    builder.addCase(getInquiryOwnerships.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        inquiryOwnerships: action?.payload,
      };
    });
    // Update Unit Status to Available
    builder.addCase(updateUnitStatusToAvailable.rejected, handleReject);
    builder.addCase(updateUnitStatusToAvailable.pending, handleLoading);
    builder.addCase(updateUnitStatusToAvailable.fulfilled, state => {
      return {
        ...state,
        loading: false,
      };
    });
    // User Option
    builder.addCase(getUserRoleOption.rejected, handleReject);
    builder.addCase(getUserRoleOption.pending, handleLoading);
    builder.addCase(getUserRoleOption.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        userInfoOption: action?.payload,
      };
    });
    // ownership input field validation flag
    builder.addCase(getOwnershipInputField.rejected, handleReject);
    builder.addCase(getOwnershipInputField.pending, handleLoading);
    builder.addCase(getOwnershipInputField.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        ownership_validation: action?.payload,
      };
    });
  },
});
export const { updateApprovalFilters, userFilterOption } = salesSlice.actions;
export default salesSlice.reducer;
