import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as visitorService from 'services/customersection';
import { DEFAULT_BOOKING_APPROVAL_FILTERS, processError } from 'utils/constant';
import { handleLoading, handleReject } from 'utils/reduxUtils';

import {
  getBookingFormDetailsParams,
  IBookingFormDetails,
  IBookingFormParams,
  ICSState,
} from './customerSectionInterface';

export const editBooking = createAsyncThunk(
  'customersection/editBooking',
  async (params: IBookingFormParams, thunkApi) => {
    try {
      const { data: res } = await visitorService.editBooking(params);
      toast.success(res.msg);
      return res;
    } catch (err) {
      const processedError = processError(err);
      toast.error(Object?.values(err.data.msg).join(', '));
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getBookingFormDetails = createAsyncThunk<
  IBookingFormDetails,
  getBookingFormDetailsParams
>('customersection/getBookingFormDetails', async (params, thunkApi) => {
  try {
    const { data: res } = await visitorService.getBookingFormDetails(params);
    return res.data;
  } catch (err) {
    const processedError = processError(err);
    console.log(err);
    return thunkApi.rejectWithValue({ error: processedError });
  }
});

const initialState: ICSState = {
  loading: false,
  booking_approval_filter: DEFAULT_BOOKING_APPROVAL_FILTERS,
  bookingdetails: {} as IBookingFormDetails,
};
const customersectionSlice = createSlice({
  name: 'customersection',
  initialState,
  reducers: {
    updateApprovalFilters: (state, action) => {
      return {
        ...state,
        booking_approval_filter: action.payload,
      };
    },
  },
  extraReducers: builder => {
    //    Booking Form Edit
    builder.addCase(editBooking.rejected, handleReject);
    builder.addCase(editBooking.pending, handleLoading);
    builder.addCase(editBooking.fulfilled, state => {
      return {
        ...state,
        loading: false,
      };
    });
    // get approval booking details
    builder.addCase(getBookingFormDetails.rejected, handleReject);
    builder.addCase(getBookingFormDetails.pending, handleLoading);
    builder.addCase(getBookingFormDetails.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        bookingdetails: action?.payload,
      };
    });
  },
});

export const { updateApprovalFilters } = customersectionSlice.actions;
export default customersectionSlice.reducer;
