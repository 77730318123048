import ExtraChargeRow from './ExtraChargeRow';

const ExtraCharges = props => {
  const {
    extraCharges,
    handleExtraChargeAdd,
    handleUpdateExtraCharge,
    handleDeleteExtraCharge,
    handle_Extra_Charge_Row_Total,
    handleTotalExtraCharge,
  } = props;

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <div className="d-flex justify-content-between">
          <h5>Extra Charges</h5>
          <div>
            <button
              className="Btn btn-lightblue-primary lbps-btn"
              type="button"
              onClick={handleExtraChargeAdd}
            >
              <div className="d-flex align-items-center justify-content-center">
                <svg
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5V19"
                    stroke="#4872F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M5 12H19"
                    stroke="#4872F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
                <span style={{ marginTop: 3, marginLeft: 5 }}> Add More</span>
              </div>
            </button>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <th>Sr No</th>
              <th>Title</th>
              <th>Distribution Method</th>
              <th>Area</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Discount</th>
              <th className="text-right">Amount</th>
              <th></th>
            </thead>
            <tbody>
              {extraCharges?.map((x, i) => (
                <ExtraChargeRow
                  key={i}
                  handle_Extra_Charge_Row_Total={handle_Extra_Charge_Row_Total}
                  handleDeleteExtraCharge={handleDeleteExtraCharge}
                  handleUpdateExtraCharge={handleUpdateExtraCharge}
                  i={i}
                  x={x}
                />
              ))}
              {/* total */}
              <tr>
                <td
                  className="text-right"
                  colSpan={9}
                  style={{ borderTop: 'none', padding: '5px 0px' }}
                >
                  <div className="total-background">
                    <label style={{ fontSize: '1.1rem' }}>
                      <span style={{ marginRight: 6 }}>Total</span>
                      <span style={{ marginRight: 6 }}>:</span>
                      <span>
                        ₹{' '}
                        {parseFloat(handleTotalExtraCharge()) < 0
                          ? 0
                          : parseFloat(handleTotalExtraCharge())}
                      </span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExtraCharges;
