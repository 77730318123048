import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar } from '@mui/material';
import { useSyncedFields } from 'hooks/useDiscountCalculator';
import { useState } from 'react';
import { DISTRIBUTION_METHOD } from 'utils/constant';

const OtherChargeRow = props => {
  const { i, x, handleOCListChange } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const oc_Base = x.other_charges_area
    ? x.other_charges_area * x.other_charges_rate
    : x.other_charges_rate;

  // discount calculations for oc
  const discountOtherCharges = useSyncedFields(
    oc_Base,
    'other_charges_disc_amt',
    'other_charges_disc_per',
    (...params) => {
      handleOCListChange(i, ...params);
    },
  );

  const action = (
    <IconButton aria-label="close" color="inherit" size="small" onClick={handleClick}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <>
      <tr key={x.id}>
        <td>{x.other_charges_no}</td>
        <td>{x.other_charges_title}</td>
        <td>
          <select
            className="form-control"
            onChange={e => {
              handleOCListChange(i, 'other_charges_distribution_method', e.target.value);
            }}
          >
            <option disabled selected>
              {isNaN(x.other_charges_distribution_method)
                ? x.other_charges_distribution_method
                : 'Select Distribution Method'}
            </option>
            {DISTRIBUTION_METHOD?.map((e, index) => {
              return (
                <option key={index} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
        </td>
        <td>
          {Number(x.other_charges_area) > 0 && (
            <input
              readOnly
              className="form-control"
              type="number"
              value={x.other_charges_area}
              // onInput={handleNumberInput}
            />
          )}
        </td>
        <td>
          <input
            readOnly
            className="form-control"
            type="number"
            value={x.other_charges_rate}
            style={{ textAlign: 'right' }}
            // onInput={handleNumberInput}
          />
        </td>
        <td>
          <div style={{ display: 'flex', gap: 10 }}>
            <input
              className="form-control"
              name="other_charges_disc_per"
              placeholder="%"
              style={{ width: '20%' }}
              type="number"
              value={x?.other_charges_disc_per !== undefined ? x.other_charges_disc_per : 0}
              onChange={e => {
                x.other_charges_distribution_method === '' ||
                x.other_charges_distribution_method === '0' ||
                x.other_charges_distribution_method === 0
                  ? handleClick()
                  : discountOtherCharges.onChangePercent(e);
              }}
              // onInput={handleNumberInput}
            />
            <input
              className="form-control mb-2"
              name="other_charges_disc_amt"
              placeholder="Amount"
              style={{ textAlign: 'right' }}
              type="number"
              value={x.other_charges_disc_amt !== undefined ? x.other_charges_disc_amt : 0}
              onChange={e => {
                x.other_charges_distribution_method === '' ||
                x.other_charges_distribution_method === '0' ||
                x.other_charges_distribution_method === 0
                  ? handleClick()
                  : discountOtherCharges.onChangeAmount(e);
              }}
              // onInput={handleNumberInput}
            />
          </div>
        </td>
        <td className="text-right">
          {/* <input
            readOnly
            className="form-control"
            type="number"
            value={x.other_charges_amount || 0}
            // onInput={handleNumberInput}
          /> */}
          <label>{x.other_charges_amount || 0}</label>
        </td>
      </tr>
      <Snackbar
        action={action}
        autoHideDuration={3000}
        message="Please Select Distribution Method"
        open={open}
        onClose={handleClick}
      />
    </>
  );
};

export default OtherChargeRow;
