import { Chip, Divider, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import SignaturePad from 'react-signature-canvas';

const AddSignatureModal = ({ show, handleClose, formikProps }) => {
  const sigCanvasRef = useRef<any>();
  const [signatureValidation, setSignatureValidation] = useState<any>();

  const { setFieldValue } = formikProps;

  const handleSave = () => {
    if (sigCanvasRef) {
      if (sigCanvasRef.current.isEmpty()) {
        setSignatureValidation('Please provide a signature first.');
      } else {
        setFieldValue('signature', sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
        handleClose();
        setSignatureValidation(null);
      }
    }
  };

  const handleClear = () => {
    if (sigCanvasRef) {
      sigCanvasRef.current.clear();
    }
  };

  return (
    <Modal
      centered
      aria-labelledby="signature-modal"
      backdrop="static"
      className="fullSizeModal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title>
          <b>
            Add Signature <Chip color="success" label="Beta" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="shwan-form">
          <Typography color={'#ff5d5d'} variant="h6">
            {signatureValidation}
          </Typography>
          <SignaturePad
            ref={ref => (sigCanvasRef.current = ref)}
            canvasProps={{ className: 'sigPad' }}
          />
        </div>
        <Divider className="my-3" />
        <div className="form-row justify-content-end mb-3 ">
          <button
            className="Btn btn-lightblue-primary lbps-btn py-2 px-4 mr-4"
            type="button"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="Btn btn-lightblue-primary lbps-btn py-2 px-4 mr-4"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            className="Btn btn-lightblue-primary  py-2 px-4"
            type="button"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddSignatureModal;
