import { config, instance } from './init';

const params = config({ multipart: false });

export const getWorkProgress = data => {
  return instance.post('/work_progress', data, params);
};
export const getWorkDetails = data => {
  return instance.post('/works_details', data, params);
};
export const getAssigneeOption = data => {
  return instance.post('/assignee', data, params);
};
export const getLevelOption = data => {
  return instance.post('/wbs_levels', data, params);
};
export const getWorkOption = data => {
  return instance.post('/wbs_works', data, params);
};
