import { config, instance } from './init';

const params = config({ multipart: false });

export const editBooking = data => {
  return instance.post('/new_booking_form_edit', data, config({ multipart: true }));
};
export const getBookingFormDetails = data => {
  return instance.post('/bookingform2_details', data, params);
};
