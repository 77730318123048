import { ReactComponent as UnauthorizedIcon } from 'assets/images/unauthorized.svg';

const Unauthorized = () => {
  return (
    <div className="d-flex align-item-center w-100 h-100 justify-content-center">
      <UnauthorizedIcon />
    </div>
  );
};

export default Unauthorized;
