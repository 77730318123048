import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@mui/material/styles';
import Layout from 'components/molecules/layout/Layout';
import WebRoutes from 'navigation/WebRoutes';
import { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { store } from 'redux/store';
import theme from 'Theme';
function App() {
  // disabling number inputs on change scroll throughout the project
  useEffect(() => {
    const disableScroll = () => {
      const el = document?.activeElement;

      if (!(el instanceof HTMLInputElement) || el?.type !== 'number') return;

      el.blur();
      setTimeout(() => el.focus({ preventScroll: true }), 0);
    };

    document.addEventListener('wheel', disableScroll);
    return () => document.removeEventListener('wheel', disableScroll);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StoreProvider store={store}>
        <Layout>
          <WebRoutes />
        </Layout>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
