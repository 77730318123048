import { styled, Typography } from '@mui/material';
const Label = styled(Typography)`
  color: #3d3f43;
  font-weight: 400;
  font-size: 18px;
`;

const Value = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #041d36;
`;
const CustomerDetails = props => {
  const { visitors_details } = props;

  const { first_name, last_name, email, phone } = visitors_details || {};

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <div className="d-flex align-items-center justify-content-between">
          <h5>Customer Details</h5>
        </div>
        <div className="form-row">
          <div className=" col form-col-gap">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Customer Name</Label>
              <Value style={{ flex: 1 }}>
                : <strong>{`${first_name || '-'} ${last_name || '-'}`}</strong>
              </Value>
            </div>
          </div>
          <div className=" col">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Phone Number</Label>
              <Value style={{ flex: 1 }}>
                : <strong>{phone || '-'}</strong>
              </Value>
            </div>
          </div>
          <div className=" col">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Email Id</Label>
              <Value style={{ flex: 1 }}>
                : <strong>{email || '-'}</strong>
              </Value>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
