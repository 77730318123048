// import { ProtectedRoutes } from 'components/molecules/ProtectedRoutes/ProtectedRoutes';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getProjectModules, getProjectPermissions } from 'redux/project';
import { useAppDispatch } from 'redux/store';
// customer section booking edit
import CS_BookingForm from 'screens/Customer/CS_Booking/CS_BookingForm';
// Credit Notes
import ApplyCredits from 'screens/Material/CreditNotes/ApplyCredits';
import CreateCreditNotes from 'screens/Material/CreditNotes/CreateCreditNotes';
import CreditNotesList from 'screens/Material/CreditNotes/CreditNotesList';
import CreditNotesPreview from 'screens/Material/CreditNotes/CreditNotesPreview';
// reports
import Construction from 'screens/Reports/Construction/Construction';
import Sales from 'screens/Reports/Sales/Sales';
// Booking
import BookingApproval from 'screens/Sales/BookingApproval/BookingApproval';
import BookingPreview from 'screens/Sales/BookingApproval/BookingPreview';
import BookingForm from 'screens/Sales/BookingChart/BookingForm';
import Unauthorized from 'screens/Unauthorized/Unauthorized';
import UE_BookingForm from 'screens/UnitEstimation/UE_BookingForm';
import UE_BookingForm_2 from 'screens/UnitEstimation/UE_BookingForm_2';

const WebRoutes = () => {
  const dispatch = useAppDispatch();

  // query param
  const project_id = Cookies.get('_hjproject_idUser_2492391');
  // const project_id = '2';

  // getting user & modules permissions
  useEffect(() => {
    dispatch(
      getProjectPermissions({
        project_id,
      }),
    );
    dispatch(
      getProjectModules({
        project_id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {/* <Route element={<ProtectedRoutes />} path="/"> */}
      <Route element={<BookingForm />} path="/bookingChart" />
      <Route element={<BookingApproval />} path="/bookingApproval" />
      <Route element={<BookingPreview />} path="/bookingPreview" />

      <Route element={<CS_BookingForm />} path="/CSbookingForm" />

      <Route element={<UE_BookingForm />} path="/UEbookingForm" />
      <Route element={<UE_BookingForm_2 />} path="/UEbookingForm2" />

      <Route element={<CreditNotesList />} path="/creditNotesList" />
      <Route element={<CreateCreditNotes />} path="/createCreditNotes" />
      <Route element={<CreditNotesPreview />} path="/CreditNotesPreview" />
      <Route element={<ApplyCredits />} path="/ApplyCredits" />
      <Route element={<Sales />} path="/salesDashboard" />
      <Route element={<Construction />} path="/constructionDashboard" />

      {/* </Route> */}
      <Route element={<Unauthorized />} path="/Unauthorized" />
    </Routes>
  );
};

export default WebRoutes;
