import InputMask from 'react-input-mask';
import { OWNERSHIP_DOCUMENTS_TYPE } from 'utils/constant';
const OwnershipRow = props => {
  const { owner, index, formikProps, handleUpdateOwnershipData, handleRemoveOwnership } = props;

  const { errors, setFieldTouched } = formikProps;

  const {
    // id,
    ownership_customer_first_name,
    ownership_customer_phone,
    ownership_customer_email,
    ownership_customer_pan,
    ownership_customer_aadhar,
    ownership_customer_dl,
    ownership_customer_election,
    ownership_customer_passport,
    document_type,
    ownership_customer_address,
    ownership_customer_dob,
    ownership_son_of,
  } = owner || {};

  const ErrorFirstName =
    errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_first_name;

  const ErrorAdhaarCard =
    errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_aadhar;

  const ErrorPhone =
    errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_phone;

  const ErrorPANCard =
    errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_pan;

  const ErrorPassport =
    errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_passport;

  const ErrorAddress =
    errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_address;

  const Errordob = errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_dob;
  const ErrorFatherName = errors?.ownership?.length && errors?.ownership[index]?.ownership_son_of;
  const ErrorEmail =
    errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_email;
  const ErrorDL = errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_dl;
  const ErrorElection =
    errors?.ownership?.length && errors?.ownership[index]?.ownership_customer_election;

  return (
    <div className="mt-3 p-3" style={{ backgroundColor: '#F6F7FB' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          className="add-comp-btn m-0 acount-act-btn red-common"
          type="button"
          onClick={() => handleRemoveOwnership(index)}
        >
          <svg
            fill="none"
            height="15"
            viewBox="0 0 6 8"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.498698 6.91667C0.498698 7.375 0.873698 7.75 1.33203 7.75H4.66537C5.1237 7.75 5.4987 7.375 5.4987 6.91667V1.91667H0.498698V6.91667ZM5.91537 0.666667H4.45703L4.04036 0.25H1.95703L1.54036 0.666667H0.0820312V1.5H5.91537V0.666667Z"
              fill="#FF5D5D"
            ></path>
          </svg>
        </button>
      </div>
      <div className="form-row mt-5">
        <div className="form-group col form-col-gap">
          <label>Name</label>
          <input
            className="form-control mb-2"
            name={`ownership.${index}.ownership_customer_first_name`}
            style={{
              backgroundColor: '#FFFFFF',
              borderColor: ErrorFirstName && 'rgba(255, 93, 93)',
            }}
            title={ownership_customer_first_name}
            type="text"
            value={ownership_customer_first_name}
            onChange={e => {
              handleUpdateOwnershipData(index, 'ownership_customer_first_name', e.target.value);
              setFieldTouched(`ownership.${index}.ownership_customer_first_name`, true);
            }}
          />
        </div>
        <div className="form-group col form-col-gap">
          <label>Father&apos;s Name</label>
          <input
            className="form-control mb-2"
            name={`ownership.${index}.ownership_son_of`}
            style={{
              backgroundColor: '#FFFFFF',
              borderColor: ErrorFatherName && 'rgba(255, 93, 93)',
            }}
            title={ownership_son_of}
            type="text"
            value={ownership_son_of}
            onChange={e => {
              handleUpdateOwnershipData(index, 'ownership_son_of', e.target.value);
              setFieldTouched(`ownership.${index}.ownership_son_of`, true);
            }}
          />
        </div>
        <div className="form-group col form-col-gap">
          <label>Phone Number</label>
          <input
            className="form-control mb-2"
            maxLength={10}
            style={{ backgroundColor: '#FFFFFF', borderColor: ErrorPhone && 'rgba(255, 93, 93)' }}
            title={ownership_customer_phone}
            type="text"
            value={ownership_customer_phone}
            onChange={e => {
              handleUpdateOwnershipData(index, 'ownership_customer_phone', e.target.value);
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col form-col-gap">
          <label>Email Id</label>
          <input
            className="form-control mb-2"
            style={{ backgroundColor: '#FFFFFF', borderColor: ErrorEmail && 'rgba(255, 93, 93)' }}
            title={ownership_customer_email}
            type="text"
            value={ownership_customer_email}
            onChange={e => {
              handleUpdateOwnershipData(index, 'ownership_customer_email', e.target.value);
            }}
          />
        </div>

        <div className="form-group col form-col-gap">
          <label>Other documents</label>
          <select
            className="form-control mb-2"
            // disabled={owner.id === undefined}
            style={{ backgroundColor: '#FFFFFF', marginBottom: 10 }}
            onChange={e => {
              handleUpdateOwnershipData(index, 'document_type', e.target.value);
            }}
          >
            <option disabled selected>
              {isNaN(document_type) ? document_type : 'Select Document Type'}
            </option>
            {OWNERSHIP_DOCUMENTS_TYPE?.map((e, index) => {
              return (
                <option key={index} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group col form-col-gap">
          <label></label>
          {document_type === 'Aadhar Card' ? (
            <InputMask
              className="form-control mb-2"
              mask={'9999-9999-9999'}
              style={{
                backgroundColor: '#FFFFFF',
                borderColor: ErrorAdhaarCard && 'rgba(255, 93, 93)',
              }}
              title={ownership_customer_aadhar}
              type="text"
              value={ownership_customer_aadhar}
              onChange={e => {
                handleUpdateOwnershipData(index, 'ownership_customer_aadhar', e.target.value);
              }}
            />
          ) : document_type === 'Election Card' ? (
            <InputMask
              className="form-control mb-2"
              mask={'aaa9999999'}
              style={{
                backgroundColor: '#FFFFFF',
                borderColor: ErrorElection && 'rgba(255, 93, 93)',
              }}
              title={ownership_customer_election}
              type="text"
              value={ownership_customer_election}
              onChange={e => {
                handleUpdateOwnershipData(index, 'ownership_customer_election', e.target.value);
              }}
            />
          ) : document_type === 'Driving License' ? (
            <InputMask
              className="form-control mb-2"
              mask={'aa99 99999999999'}
              style={{ backgroundColor: '#FFFFFF', borderColor: ErrorDL && 'rgba(255, 93, 93)' }}
              title={ownership_customer_dl}
              type="text"
              value={ownership_customer_dl}
              onChange={e => {
                handleUpdateOwnershipData(index, 'ownership_customer_dl', e.target.value);
              }}
            />
          ) : document_type === 'Passport' ? (
            <InputMask
              className="form-control mb-2"
              mask={'a9999999'}
              style={{
                backgroundColor: '#FFFFFF',
                borderColor: ErrorPassport && 'rgba(255, 93, 93)',
              }}
              title={ownership_customer_passport}
              type="text"
              value={ownership_customer_passport}
              onChange={e => {
                handleUpdateOwnershipData(index, 'ownership_customer_passport', e.target.value);
              }}
            />
          ) : undefined}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col form-col-gap">
          <label>Pan</label>
          <InputMask
            className="form-control mb-2"
            mask="aaaaa9999a"
            style={{ backgroundColor: '#FFFFFF', borderColor: ErrorPANCard && 'rgba(255, 93, 93)' }}
            title={ownership_customer_pan}
            type="text"
            value={ownership_customer_pan}
            onChange={e => {
              handleUpdateOwnershipData(index, 'ownership_customer_pan', e.target.value);
            }}
          />
        </div>
        <div className="form-group col form-col-gap">
          <label>Address</label>
          <input
            className="form-control mb-2"
            name={`ownership.${index}.ownership_customer_address`}
            style={{
              backgroundColor: '#FFFFFF',
              borderColor: ErrorAddress && 'rgba(255, 93, 93)',
            }}
            title={ownership_customer_address}
            type="text"
            value={ownership_customer_address}
            onChange={e => {
              handleUpdateOwnershipData(index, 'ownership_customer_address', e.target.value);
              setFieldTouched(`ownership.${index}.ownership_customer_address`, true);
            }}
          />
        </div>
        <div className="form-group col form-col-gap">
          <label>Date Of Birth</label>
          <input
            className="form-control mb-2"
            style={{ backgroundColor: '#FFFFFF', borderColor: Errordob && 'rgba(255, 93, 93)' }}
            title={ownership_customer_dob}
            type="date"
            value={ownership_customer_dob}
            onChange={e => {
              handleUpdateOwnershipData(index, 'ownership_customer_dob', e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnershipRow;
