const CustomInstallmentRow = props => {
  const { e, i, formikProps, handlePaymentScheduleUpdate, handleDeleteInstallment } = props;
  const { errors } = formikProps;

  const ErrorDueDate =
    errors?.installments?.length && errors?.installments[i]?.installment_due_date;

  return (
    <tr key={`${i}_${e.id}`}>
      <td>{i + 1}</td>
      <td>
        <input
          className="form-control"
          disabled={!e.custom}
          name={`e.${i}.custom_payment_installment`}
          value={e.custom_payment_installment}
          onChange={x => {
            handlePaymentScheduleUpdate(i, 'custom_payment_installment', x.target.value);
          }}
        />
      </td>
      <td>
        <input
          className="form-control"
          name={`e.${i}.installment_due_date`}
          style={{ borderColor: ErrorDueDate && 'rgba(255, 93, 93)' }}
          type="date"
          value={e.installment_due_date}
          onChange={x => {
            handlePaymentScheduleUpdate(i, 'installment_due_date', x.target.value);
          }}
        />
      </td>

      <td>
        {!e.lastRow && e.custom_payment_installment !== 'Other Charges (Separately)' && (
          <input
            className="form-control"
            name={`e.${i}.installment_per`}
            type="number"
            value={e.installment_per}
            onChange={x => {
              handlePaymentScheduleUpdate(i, 'installment_per', x.target.value);
            }}
          />
        )}
      </td>
      <td>
        {!e.lastRow && e.custom_payment_installment !== 'Other Charges (Separately)' && (
          <input
            readOnly
            className="form-control"
            name={`e.${i}.installment_basic_amt`}
            type="number"
            value={
              e.installment_basic_amt !== 0 ? e.installment_basic_amt : e.installment_basic_amt
            }
          />
        )}
      </td>
      <td>
        <input
          readOnly
          className="form-control"
          name={`e.${i}.installment_otherchages_amt`}
          type="number"
          value={
            e.installment_otherchages_amt !== 0
              ? Number(e.installment_otherchages_amt).toFixed(2)
              : Number(e.installment_otherchages_amt).toFixed(2)
          }
        />
      </td>
      <td>
        <input
          readOnly
          className="form-control"
          name={`e.${i}.gst`}
          type="number"
          value={e.gst !== 0 ? Number(e.gst).toFixed(2) : Number(e.gst).toFixed(2)}
        />
      </td>
      <td>
        <input
          readOnly
          className="form-control"
          name={`e.${i}.installment_extrachages_amt`}
          type="number"
          value={
            e.installment_extrachages_amt !== 0
              ? Number(e.installment_extrachages_amt).toFixed(2)
              : Number(e.installment_extrachages_amt).toFixed(2)
          }
        />
      </td>
      <td>
        <input
          readOnly
          className="form-control"
          name={`e.${i}.installment_amount`}
          type="number"
          value={
            e.installment_amount !== 0
              ? Number(e.installment_amount).toFixed(2)
              : Number(e.installment_amount).toFixed(2)
          }
        />
      </td>
      <td>
        {!e.lastRow ? (
          <button
            className="add-comp-btn m-0 acount-act-btn red-common"
            type="button"
            onClick={() => handleDeleteInstallment(i)}
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 6 8"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.498698 6.91667C0.498698 7.375 0.873698 7.75 1.33203 7.75H4.66537C5.1237 7.75 5.4987 7.375 5.4987 6.91667V1.91667H0.498698V6.91667ZM5.91537 0.666667H4.45703L4.04036 0.25H1.95703L1.54036 0.666667H0.0820312V1.5H5.91537V0.666667Z"
                fill="#FF5D5D"
              ></path>
            </svg>
          </button>
        ) : undefined}
      </td>
    </tr>
  );
};

export default CustomInstallmentRow;
