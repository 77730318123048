import CloseIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import theme from 'Theme';

const TableRowFonts = styled(Typography)`
  && {
    font-size: 18px;
    weight: 500;
  }
`;

const StyleTableCell = styled(TableCell)(() => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'unset',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '4px',
      position: 'relative',
      padding: '10px 16px',
      '&:not(&:nth-child(1) )': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 20,
          left: 0,
          bottom: 20,
          width: '1px',
          background: `${theme.palette.grey[300]}`,
        },
      },
    },
    [`&.${tableCellClasses.body}`]: {
      border: '0px 0px 2px 0px',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      position: 'relative',
    },
  };
});

const CreditRow = ({ index, bill, RemoveCreditBill, modulePermission, isProjectAdmin }) => {
  const {
    invoice_id,
    invoice_no,
    credit_amount,
    date,
    id: credit_bill_id,
    grand_total,
  } = bill || {};

  return (
    <TableRow key={index}>
      <StyleTableCell>
        <TableRowFonts>{dayjs(date).format('DD MMM YYYY') || '-'}</TableRowFonts>
      </StyleTableCell>
      <StyleTableCell>
        <TableRowFonts style={{ color: '#4872f4' }}>{invoice_no || '-'}</TableRowFonts>
      </StyleTableCell>
      <StyleTableCell>
        <TableRowFonts>{`₹ ${grand_total || 0}`}</TableRowFonts>
      </StyleTableCell>
      <StyleTableCell>
        <TableRowFonts>{`₹ ${credit_amount || 0}`}</TableRowFonts>
      </StyleTableCell>
      <StyleTableCell>
        {modulePermission?.admin || isProjectAdmin ? (
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => RemoveCreditBill(invoice_id, credit_bill_id)}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </StyleTableCell>
    </TableRow>
  );
};

export default CreditRow;
