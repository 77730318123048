import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { DECIMAL_REGEX, DISTRIBUTION_METHOD } from 'utils/constant';

const ExtraChargeRow = props => {
  const {
    i,
    x,
    handleUpdateExtraCharge,
    handle_Extra_Charge_Row_Total,
    handleDeleteExtraCharge,
    EDIT,
  } = props;
  const { extra_charges_area = 0, extra_charges_rate = 0 } = x || {};
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const base = EDIT
    ? extra_charges_area > 0
      ? Number(extra_charges_area) * Number(extra_charges_rate)
      : Number(extra_charges_rate)
    : extra_charges_area === 0
    ? Number(extra_charges_rate)
    : Number(extra_charges_area) * Number(extra_charges_rate);

  // ec disc amt calculation
  function handleExtraChargesDiscAmt(e) {
    const { valueAsNumber: amount = 0 } = e.target;

    // Fixing the amount if it is greater than base amount
    const fixAmount = amount > base ? base : amount;
    // Set to zero if less than zero
    const newAmount = isNaN(fixAmount) || fixAmount < 0 ? 0 : fixAmount;
    // matches for two decimals
    if (DECIMAL_REGEX.test(String(newAmount))) {
      handleUpdateExtraCharge(i, 'extra_charges_disc_amt', newAmount);

      // Calculate the percentage based on the new amount and update the formik value for the percentage field
      const percent = parseFloat(((newAmount / base) * 100).toFixed(2));

      if (newAmount === 0) {
        handleUpdateExtraCharge(i, 'extra_charges_disc_amt', null);
        handleUpdateExtraCharge(i, 'extra_charges_disc_per', 0);
      } else if (percent >= 100 && newAmount > base) {
        toast.warning('Discount Amount cannot be more than Basic Amount');
        handleUpdateExtraCharge(i, 'extra_charges_disc_per', 100);
      } else {
        handleUpdateExtraCharge(i, 'extra_charges_disc_per', percent);
      }
    }
  }
  // ec disc % calculation
  function handleExtraChargesDiscPer(e) {
    const { valueAsNumber: percent = 0 } = e.target;

    // Fixing the amount if it is greater than base amount
    const fixPercent = percent > 100 ? 100 : percent;
    // Set to zero if less than zero
    const newPercent = isNaN(fixPercent) || fixPercent < 0 ? 0 : fixPercent;
    // matches for two decimals
    if (DECIMAL_REGEX.test(String(newPercent))) {
      handleUpdateExtraCharge(i, 'extra_charges_disc_per', newPercent);

      const amount = parseFloat(((base * newPercent) / 100).toFixed(2));

      if (newPercent === 0) {
        handleUpdateExtraCharge(i, 'extra_charges_disc_per', null);
        handleUpdateExtraCharge(i, 'extra_charges_disc_amt', 0);
      } else if (percent > 100 && amount > base) {
        toast.warning('Discount % cannot be more than 100%');
        handleUpdateExtraCharge(i, 'extra_charges_disc_amt', base);
        handleUpdateExtraCharge(i, 'extra_charges_disc_per', 100);
      } else {
        handleUpdateExtraCharge(i, 'extra_charges_disc_amt', amount);
      }
    }
  }

  // ec rate change
  function handleExtraChargesRateChange(e) {
    handleUpdateExtraCharge(i, 'extra_charges_rate', e.target.value);
    handleUpdateExtraCharge(i, 'extra_charges_disc_amt', 0);
    handleUpdateExtraCharge(i, 'extra_charges_disc_per', 0);
    handle_Extra_Charge_Row_Total();
  }

  // ec area change
  function handleExtraChargesAreaChange(e) {
    handleUpdateExtraCharge(i, 'extra_charges_area', e.target.value);
    handleUpdateExtraCharge(i, 'extra_charges_disc_amt', 0);
    handleUpdateExtraCharge(i, 'extra_charges_disc_per', 0);
    handle_Extra_Charge_Row_Total();
  }

  const action = (
    <IconButton aria-label="close" color="inherit" size="small" onClick={handleClick}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <tr key={x.id}>
        <td>{i + 1}</td>
        <td>
          <input
            className="form-control mb-2"
            title={x?.extra_charges_title}
            type="text"
            value={x?.extra_charges_title}
            onChange={e => {
              handleUpdateExtraCharge(i, 'extra_charges_title', e.target.value);
              handle_Extra_Charge_Row_Total();
            }}
          />
        </td>
        <td>
          <select
            className="form-control mb-2"
            onChange={e => {
              handleUpdateExtraCharge(i, 'extra_charges_distribution_method', e.target.value);
              handle_Extra_Charge_Row_Total();
            }}
          >
            <option disabled selected>
              {isNaN(x.extra_charges_distribution_method)
                ? x.extra_charges_distribution_method
                : 'Select Distribution Method'}
            </option>
            {DISTRIBUTION_METHOD?.map((e, index) => {
              return (
                <option key={index} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
        </td>
        <td>
          {(!EDIT || (EDIT && x.extra_charges_area > 0)) && x.amount_type === 'ratebase_amt' && (
            <input
              readOnly
              className="form-control mb-2"
              type="number"
              value={x?.extra_charges_area}
              onChangeCapture={handleExtraChargesAreaChange}
            />
          )}
        </td>
        <td>
          <input
            className="form-control mb-2"
            style={{ textAlign: 'right' }}
            type="number"
            value={x.extra_charges_rate}
            onChange={handleExtraChargesRateChange}
          />
        </td>
        <td>
          <div style={{ display: 'flex', gap: 10 }}>
            <input
              className="form-control"
              name="extra_charges_disc_per"
              placeholder="%"
              style={{ width: '20%' }}
              type="number"
              value={x.extra_charges_disc_per}
              onChange={e => {
                (EDIT && x.extra_charges_distribution_method === 0) ||
                x.extra_charges_distribution_method === '0' ||
                x.extra_charges_distribution_method === ''
                  ? handleClick()
                  : handleExtraChargesDiscPer(e);
                handle_Extra_Charge_Row_Total();
              }}
            />
            <input
              className="form-control mb-2"
              name="extra_charges_disc_amt"
              placeholder="Amount"
              style={{ textAlign: 'right' }}
              type="number"
              value={x.extra_charges_disc_amt}
              onChange={e => {
                (EDIT && x.extra_charges_distribution_method === 0) ||
                x.extra_charges_distribution_method === '0' ||
                x.extra_charges_distribution_method === ''
                  ? handleClick()
                  : handleExtraChargesDiscAmt(e);
                handle_Extra_Charge_Row_Total();
              }}
            />
          </div>
        </td>

        <td className="text-right">
          {/* <input
      readOnly
      className="form-control mb-2"
      type="number"
      value={x.extra_charges_amt}
      // onInput={handleNumberInput}
    /> */}
          <label>{x.extra_charges_amt}</label>
        </td>
        {/* <td></td> */}
        <td>
          <button
            className="add-comp-btn m-0 acount-act-btn red-common"
            type="button"
            onClick={() => handleDeleteExtraCharge(i)}
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 6 8"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.498698 6.91667C0.498698 7.375 0.873698 7.75 1.33203 7.75H4.66537C5.1237 7.75 5.4987 7.375 5.4987 6.91667V1.91667H0.498698V6.91667ZM5.91537 0.666667H4.45703L4.04036 0.25H1.95703L1.54036 0.666667H0.0820312V1.5H5.91537V0.666667Z"
                fill="#FF5D5D"
              ></path>
            </svg>
          </button>
        </td>
      </tr>
      <Snackbar
        action={action}
        autoHideDuration={3000}
        message="Please Select Distribution Method"
        open={open}
        onClose={handleClick}
      />
    </>
  );
};

export default ExtraChargeRow;
