import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const PrimaryButton = styled(Button)(()=> {

  return {
    background: 'none',
    color: '#4872F4',
    transition: 'unset',
    boxShadow: 'none',
    textTransform: 'none',
    '& .MuiButton-endIcon': {
      color: '#4872F4',
    },
    '&:hover': {
      background: 'none',
      boxShadow: 'unset',
    },
    '&:active': {
      background: 'none',
      boxShadow: 'unset',
    },
  };
});
