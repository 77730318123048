import { styled, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { getInquiryOwnerships } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

import AddCustomerModal from './AddCustomerModal';

const Label = styled(Typography)`
  color: #3d3f43;
  font-weight: 400;
  font-size: 18px;
`;

const Value = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #041d36;
`;

const CustomerDetails = props => {
  const {
    formikProps,
    project_id,
    customerDetails,
    setCustomerDetails,
    EDIT,
    inquiryPermission,
    customer_first_name,
    customer_last_name,
    customer_email,
    customer_phone,
  } = props;
  const { setFieldValue, handleBlur, errors } = formikProps;

  const [show, setShow] = useState(false);

  const dispatch = useAppDispatch();

  const { customerList, visitorList, inquiryOwnerships } = useAppSelector(s => s.sales);

  // customers options
  const customerOptions = useMemo(() => {
    const visitorOptions = visitorList?.map(e => ({
      label: `${e.first_name} ${e.last_name} - [${e.phone}]`,
      value: e.id,
      details: e,
    }));

    const customerOptions = customerList?.map(e => ({
      label: `${e.first_name} ${e.last_name} - [${e.phone}]`,
      value: e.id,
      details: e,
    }));

    return [...visitorOptions, ...customerOptions];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorList]);

  const handleVisitorChange = e => {
    setCustomerDetails(e?.details);
    setFieldValue('visitors_id', e?.details.id);

    dispatch(
      getInquiryOwnerships({
        project_id,
        visitor_id: e?.details.id,
      }),
    );
  };

  useEffect(() => {
    if (inquiryOwnerships?.from_event === 'yes') {
      const _ownership = [
        {
          id: 1,
          ownership_customer_first_name: `${inquiryOwnerships?.first_name} ${inquiryOwnerships?.last_name}`,
          ownership_customer_phone: inquiryOwnerships?.phone,
          ownership_customer_email: inquiryOwnerships?.email,
          ownership_customer_pan: inquiryOwnerships?.pan_no,
          document_type:
            inquiryOwnerships?.other_document === 'aadhar_card'
              ? 'Aadhar Card'
              : inquiryOwnerships?.other_document === 'driving_license'
              ? 'Driving License'
              : inquiryOwnerships?.other_document === 'election_card'
              ? 'Election Card'
              : '',
          ownership_customer_aadhar: inquiryOwnerships?.aadhar_no,
          ownership_customer_dl: inquiryOwnerships?.dl_no,
          ownership_customer_election: inquiryOwnerships?.election_no,
        },
      ];
      setFieldValue('ownership', _ownership);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryOwnerships]);

  const toggleModal = () => setShow(v => !v);
  return (
    <div className="booking-form-box shwan-form mt-4">
      <AddCustomerModal handleClose={toggleModal} project_id={project_id} show={show} />

      <div className="booking-form-col-12">
        <div className="d-flex align-items-center justify-content-between">
          <h5>Customer Details</h5>
          {(!EDIT && inquiryPermission?.add) ||
          inquiryPermission?.editor ||
          inquiryPermission?.approval ||
          inquiryPermission?.admin ? (
            <button
              className="Btn btn-lightblue-primary lbps-btn mr-0"
              type="button"
              onClick={toggleModal}
            >
              <div className="d-flex align-items-center justify-content-center">
                <svg
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5V19"
                    stroke="#4872F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    d="M5 12H19"
                    stroke="#4872F4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
                <span style={{ marginTop: 3, marginLeft: 5 }}> Add Customer</span>
              </div>
            </button>
          ) : undefined}
        </div>

        <div className="form-row">
          <div className="col-12">
            <label className="mandate-star" style={{ fontSize: 16, fontWeight: 500 }}>
              Select customer
            </label>
            <Select
              closeMenuOnSelect={true}
              isDisabled={EDIT}
              noOptionsMessage={() => 'Loading...'}
              options={customerOptions}
              placeholder={
                EDIT
                  ? `${customer_first_name} ${customer_last_name} - [${customer_phone}]`
                  : 'Existing Inquiry'
              }
              styles={{
                container: (base:any) => ({
                  ...base,
                  width: '31%',
                  // marginTop: 10,
                  // marginBottom: 50,
                }),
                control: (base:any) => ({
                  ...base,
                  borderColor: errors.visitors_id ? 'rgba(255, 93, 93)' : 'hsl(0, 0%, 80%)',
                }),
              }}
              onBlur={handleBlur}
              onChange={handleVisitorChange}
            />
          </div>
        </div>
        {customerDetails || EDIT ? (
          <>
            <div className="form-row mt-5">
              <div className="form-group col form-col-gap">
                <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
                  <Label>Customer Name</Label>
                  <Value style={{ flex: 1 }}>
                    :{' '}
                    <strong>{`${
                      customerDetails
                        ? `${customerDetails?.first_name} ${customerDetails?.last_name}`
                        : `${customer_first_name} ${customer_last_name}`
                    }`}</strong>
                  </Value>
                </div>
              </div>
              <div className="form-group col">
                <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
                  <Label>Phone Number</Label>
                  <Value style={{ flex: 1 }}>
                    :{' '}
                    <strong>{`${
                      customerDetails ? `${customerDetails?.phone}` : `${customer_phone}`
                    }`}</strong>
                  </Value>
                </div>
              </div>
              <div className="form-group col">
                <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
                  <Label>Email Id</Label>
                  <Value style={{ flex: 1 }}>
                    :{' '}
                    <strong>{`${
                      customerDetails
                        ? `${customerDetails?.email || '-'}`
                        : `${customer_email || '-'}`
                    }`}</strong>
                  </Value>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerDetails;
