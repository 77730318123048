import { Stack, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { getBankList } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#5E6D7C',
    boxSizing: 'border-box',
  },
}));

const Loan = props => {
  const { formikProps, isToggle, toggleLoanSwitch, EDIT } = props;
  const { values, handleChange, setFieldValue } = formikProps;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBankList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // redux state values
  const { banksList } = useAppSelector(s => s.sales);

  //BankLists Options
  const bankListOptions = useMemo(() => {
    return banksList?.map(x => ({
      label: x.title,
      value: x.id,
    }));
  }, [banksList]);

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Loan Details</h5>

        <div className="d-flex align-items-center justify-content-between">
          <label>Do you wish to take a loan?</label>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography color="#041D36">{isToggle ? 'Yes' : 'No'}</Typography>
            <AntSwitch
              checked={isToggle}
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={toggleLoanSwitch}
            />
          </Stack>
        </div>

        {isToggle && (
          <>
            <div className="form-row mt-3">
              <div className="form-group col-3 form-col-gap">
                <label>Loan amount</label>
                <input
                  className="form-control"
                  id="loan_amt"
                  name="loan_amt"
                  type="text"
                  value={values.loan_amt}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-3">
                <label>Select bank</label>
                <Select
                  closeMenuOnSelect={true}
                  defaultValue={
                    EDIT ? bankListOptions?.find(e => e.value === values.bank_id) : null
                  }
                  name="bank"
                  options={bankListOptions}
                  placeholder="Banks List"
                  styles={{
                    container: (base:any) => ({
                      ...base,
                      // width: '81%',
                      marginTop: 0,
                    }),
                  }}
                  // value={bankListOptions?.find(e => e.value === values.bank_id)}
                  onChange={e => setFieldValue('bank', e.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  cols={100}
                  id="loan_remarks"
                  name="loan_remarks"
                  rows={10}
                  style={{ height: 'unset' }}
                  value={values.loan_remarks}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Loan;
