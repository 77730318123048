import { config, instance } from './init';

const params = config({ multipart: false });

//Project Permissions
export const getProjectPermissions = data => {
  return instance.post('/get_user_permission', data, params);
};

export const getProjectCommonData = data => {
  return instance.post('/get_common_info', data, params);
};

export const getProjectModules = data => {
  return instance.post('/get_project_modules_info', data, params);
};
