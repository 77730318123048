import { store } from 'redux/store';

import { DEFAULT_ADMIN_PERMISSIONS } from './constant';

export function getPermissions(moduleTitle) {
  const { permissions, isProjectAdmin, modulesData } = store.getState().project;
  const { submodules = [] } = modulesData || {};

  if (isProjectAdmin) {
    return DEFAULT_ADMIN_PERMISSIONS;
  }

  const moduleData = submodules.find(i => i.title === moduleTitle);

  if (moduleData) {
    const modulePermissions = permissions[moduleData?.id];
    return modulePermissions;
  }

  return false;
}
