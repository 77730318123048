import { Cancel as CancelIcon } from '@mui/icons-material';
import { Button, Divider, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { getUserRoleOption, userFilterOption } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { DEFAULT_USER_FILTER } from 'utils/constant';

const ApplyBtn = styled(Button)`
  background: rgba(72, 114, 244, 0.1);
  color: #4872f4;
  margin: 5px 10px 0;
  padding: 4px 15px;
  font-size: 16px;
  transition: background-color 0.3s;
  border-radius: 8px;
  box-shadow: none;
  text-transform: none;
  &:hover {
    color: #fff;
    background-color: #4872f4;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }
`;

const ClearBtn = styled(Button)`
  background: rgba(255, 93, 93, 0.2);
  color: #ff5d5d;
  margin: 5px 0 0 10px;
  padding: 4px 15px;
  font-size: 16px;
  transition: background-color 0.5s;
  border-radius: 8px;
  box-shadow: none;
  text-transform: none;
  border: #ff5d5d;
  &:hover {
    background-color: #ff5d5d;
    color: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }
`;
const PROJECT_ID = Cookies.get('_hjproject_idUser_2492391');
// const PROJECT_ID = '17';
const SalesDashboardFilter = props => {
  const { toggleDrawer, open } = props;

  const dispatch = useAppDispatch();

  // redux state values
  const { userInfoOption, userFilter } = useAppSelector(s => s.sales);

  useEffect(() => {
    dispatch(getUserRoleOption({ project_id: PROJECT_ID }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    await dispatch(userFilterOption(values));
    toggleDrawer();
  };
  const clearForm = async () => {
    await dispatch(userFilterOption(DEFAULT_USER_FILTER));
    toggleDrawer();
  };

  const formikProps = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: userFilter,
    onSubmit,
  });

  const { setFieldValue, values } = formikProps;

  const userOptions = useMemo(() => {
    const userLists = userInfoOption?.map(e => ({
      label: `${e.first_name} ${e.last_name}`,
      value: e.id.toString(),
    }));
    return [...userLists];
  }, [userInfoOption]);

  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: '12px',
        },
      }}
      onClose={toggleDrawer}
    >
      <div
        style={{
          height: '48px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px 15px',
          marginBottom: '10px',
        }}
      >
        <p>Sales Dashboard Filter</p>
        <CancelIcon
          sx={{
            backgroundColor: '#FFE1E1',
            borderRadius: '80px',
            height: '26.67px',
            width: '26.67px',
            color: '#FF5D5D',
            cursor: 'pointer',
          }}
          onClick={toggleDrawer}
        />
      </div>
      <Divider sx={{ mt: 2 }} />
      <div style={{ width: '388px' }}>
        <div style={{ padding: '0 15px', gap: '16px' }}>
          <div className="d-flex justify-content-end">
            <ApplyBtn onClick={onSubmit}>Apply</ApplyBtn>
            <ClearBtn onClick={clearForm}>Clear</ClearBtn>
          </div>
          <div className="my-3">
            <Typography className="muted-text" variant="caption">
              Select User
            </Typography>
            <Select
              closeMenuOnSelect={true}
              name="user_id"
              options={userOptions}
              placeholder="Select User"
              styles={{
                container: base => ({
                  ...base,
                }),
              }}
              onChange={v => setFieldValue('user_id', v.value)}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default SalesDashboardFilter;
