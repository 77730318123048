import { DISTRIBUTION_METHOD } from 'utils/constant';

const ExtraChargeRow = props => {
  const { i, x, handleUpdateExtraCharge, handle_Extra_Charge_Row_Total, handleDeleteExtraCharge } =
    props;

  // ec rate change
  function handleExtraChargesRateChange(e) {
    handleUpdateExtraCharge(i, 'extra_charges_rate', e.target.value);
    handleUpdateExtraCharge(i, 'extra_charges_disc_amt', 0);
    handleUpdateExtraCharge(i, 'extra_charges_disc_per', 0);
    handle_Extra_Charge_Row_Total();
  }

  // ec area change
  function handleExtraChargesAreaChange(e) {
    handleUpdateExtraCharge(i, 'extra_charges_area', e.target.value);
    handleUpdateExtraCharge(i, 'extra_charges_disc_amt', 0);
    handleUpdateExtraCharge(i, 'extra_charges_disc_per', 0);
    handle_Extra_Charge_Row_Total();
  }

  return (
    <tr key={x.id}>
      <td>{i + 1}</td>
      <td>
        <input
          className="form-control mb-2"
          title={x?.extra_charges_title}
          type="text"
          value={x?.extra_charges_title}
          onChange={e => {
            handleUpdateExtraCharge(i, 'extra_charges_title', e.target.value);
            handle_Extra_Charge_Row_Total();
          }}
        />
      </td>
      <td>
        <select
          className="form-control mb-2"
          onChange={e => {
            handleUpdateExtraCharge(i, 'extra_charges_distribution_method', e.target.value);
            handle_Extra_Charge_Row_Total();
          }}
        >
          <option disabled selected>
            {isNaN(x.extra_charges_distribution_method)
              ? x.extra_charges_distribution_method
              : 'Proportionately with all installment'}
          </option>
          {DISTRIBUTION_METHOD?.map((e, index) => {
            return (
              <option key={index} value={e}>
                {e}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        {x.amount_type === 'ratebase_amt' && (
          <input
            readOnly
            className="form-control mb-2"
            type="number"
            value={x?.extra_charges_area}
            onChangeCapture={handleExtraChargesAreaChange}
          />
        )}
      </td>
      <td>
        <input
          className="form-control mb-2"
          style={{ textAlign: 'right' }}
          type="number"
          value={x.extra_charges_rate}
          onChange={handleExtraChargesRateChange}
        />
      </td>
      <td className="text-right">{x.extra_charges_total}</td>
      <td>
        <button
          className="add-comp-btn m-0 acount-act-btn red-common"
          type="button"
          onClick={() => handleDeleteExtraCharge(i)}
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 6 8"
            width="8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.498698 6.91667C0.498698 7.375 0.873698 7.75 1.33203 7.75H4.66537C5.1237 7.75 5.4987 7.375 5.4987 6.91667V1.91667H0.498698V6.91667ZM5.91537 0.666667H4.45703L4.04036 0.25H1.95703L1.54036 0.666667H0.0820312V1.5H5.91537V0.666667Z"
              fill="#FF5D5D"
            ></path>
          </svg>
        </button>
      </td>
    </tr>
  );
};

export default ExtraChargeRow;
