import { config, instance } from './init';

const params = config({ multipart: false });

//Credit Notes
export const getCreditNotesList = data => {
  return instance.post('/list_credit_notes', data, params);
};

export const getCreditNotesDetails = data => {
  return instance.post('/credit_notes_details', data, params);
};

export const addCreditNotes = data => {
  return instance.post('/add_credit_notes', data, config({ multipart: true }));
};

export const getSupplierList = data => {
  return instance.post('po/getsuppliersList', data, params);
};

export const getListInvoice = data => {
  return instance.post('/list_invoice', data, params);
};

export const getMaterial = data => {
  return instance.post('/get_material', data, params);
};

export const getSubMaterial = data => {
  return instance.post('/get_submaterial', data, params);
};

export const getCreditNotsLogs = data => {
  return instance.post('/credit_notes_log', data, params);
};

export const getCreditBillsList = data => {
  return instance.post('/list_invoice', data, params);
};

export const addCreditBills = data => {
  return instance.post('/add_credit_bill', data, params);
};

export const deleteBill = data => {
  return instance.post('/delete_credit_bill', data, params);
};

export const getInvoicematerials = data => {
  return instance.post('/invoice_materials', data, params);
};

export const getInvoiceSubMaterials = data => {
  return instance.post('/invoice_sub_materials', data, params);
};
export const deleteCreditNots = data => {
  return instance.post('/delete_credit_notes', data, params);
};
export const editCreditNotes = data => {
  return instance.post('/update_credit_notes', data, config({ multipart: true }));
};
