import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';
import { CommonParams } from 'redux/interface';
import * as projectService from 'services/project';
import { processError } from 'utils/constant';
import { handleLoading, handleReject } from 'utils/reduxUtils';

import { ICommonData, IPermission, IPermissions, IProjectState } from './projectInterface';

export const getProjectPermissions = createAsyncThunk<IPermission, CommonParams>(
  'project/getProjectPermissions',
  async (params, thunkApi) => {
    try {
      const { data: res } = await projectService.getProjectPermissions(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getProjectCommonData = createAsyncThunk(
  'project/getProjectCommonInfo',
  async (params: CommonParams, thunkApi) => {
    try {
      const { data: res } = await projectService.getProjectCommonData(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getProjectModules = createAsyncThunk(
  'project/getProjectModules',
  async (params: CommonParams, thunkApi) => {
    try {
      const { data: res } = await projectService.getProjectModules(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

const initialState: IProjectState = {
  loading: false,
  isProjectAdmin: 0,
  permissions: {} as IPermissions,
  commonData: {} as ICommonData,
  modulesData: {} as ICommonData,
};

const salesSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // project permissions
    builder.addCase(getProjectPermissions.rejected, handleReject);
    builder.addCase(getProjectPermissions.pending, handleLoading);
    builder.addCase(getProjectPermissions.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        isProjectAdmin: action.payload.isAdmin,
        permissions: action.payload.permissions,
      };
    });
    // project common data
    builder.addCase(getProjectCommonData.rejected, handleReject);
    builder.addCase(getProjectCommonData.pending, handleLoading);
    builder.addCase(getProjectCommonData.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        commonData: action.payload,
      };
    });
    // project modules data
    builder.addCase(getProjectModules.rejected, handleReject);
    builder.addCase(getProjectModules.pending, handleLoading);
    builder.addCase(getProjectModules.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        modulesData: action.payload,
      };
    });
  },
});

export default salesSlice.reducer;
