import { styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
const Label = styled(Typography)`
  color: #53575b;
  font-weight: 400;
  font-size: 18px;
`;

const Value = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #041d36;
`;
const UnitInfo = props => {
  const { formikProps, unit_details } = props;

  const { handleBlur, handleChange, values } = formikProps;

  // unit details
  const { unit_info, super_build_up_area, terracearea } = unit_details || {};

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Unit Information</h5>

        <div className="form-row">
          <div className="form-group col-sm-4 col-md-3 col-lg-4">
            <label>Unit Reservation Date</label>
            <input
              className="form-control"
              name="unit_reserved_date"
              type="date"
              value={dayjs(values.unit_reserved_date).format('YYYY-MM-DD')}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-sm-4 col-md-3 col-lg-4  ">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Unit Info</Label>
              <Value style={{ flex: 1 }}>
                : <strong>{unit_info}</strong>
              </Value>
            </div>
          </div>
          <div className="form-group col-sm-4 col-md-3 col-lg-4">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Super Buildup Area </Label>
              <Value style={{ flex: 1 }}>
                : <strong>{super_build_up_area}</strong>
              </Value>
            </div>
          </div>
          {/* <div className="form-group col-sm-4 col-md-3 col-lg-4">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Car Parking No </Label>
              <Value style={{ flex: 1 }}>
                : <strong>{car_parking_no}</strong>
              </Value>
            </div>
          </div> */}

          <div className="form-group col-sm-4 col-md-3 col-lg-4">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Terrace Area </Label>
              <Value style={{ flex: 1 }}>
                : <strong>{terracearea}</strong>
              </Value>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitInfo;
