import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Label = styled(Typography)`
  color: #3d3f43;
  font-weight: 400;
  font-size: 18px;
`;

const Value = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #041d36;
`;

const BrokerDetails = props => {
  const { broker_details, formikProps } = props;

  const { first_name, last_name, email, phone } = broker_details || {};

  const { values, handleChange } = formikProps;

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <div className="d-flex align-items-center justify-content-between">
          <h5>Broker Details</h5>
        </div>
        <div className="form-row mt-3">
          <div className="form-group col form-col-gap">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Broker Name</Label>
              <Value style={{ flex: 1 }}>
                : <strong>{`${first_name || '-'} ${last_name || '-'}`}</strong>
              </Value>
            </div>
          </div>
          <div className="form-group col">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Phone Number</Label>
              <Value style={{ flex: 1 }}>
                : <strong>{phone || '-'}</strong>
              </Value>
            </div>
          </div>
          <div className="form-group col">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Email Id</Label>
              <Value style={{ flex: 1 }}>
                : <strong>{email || ''} </strong>
              </Value>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label>Brokerage amount</label>
            <input
              className="form-control"
              name="brokerage"
              style={{ width: '31%' }}
              type="text"
              value={values.brokerage || '-'}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label>Remark</label>
            <textarea
              className="form-control"
              name="broker_remark"
              value={values.broker_remark || '-'}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerDetails;
