import OtherChargeRow from './OtherChargeRow';

const OtherCharges = props => {
  const { formikProps, handleTotalOtherCharge, OCList, handleOCListChange } = props;

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Other Charges</h5>

        <div>
          <table className="table">
            <thead>
              <th>Sr No</th>
              <th>Title</th>
              <th>Distribution Method</th>
              <th>Area</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Discount</th>
              <th className="text-right">Amount</th>
            </thead>
            {/* {values.calculation_method ? ( */}
            <tbody>
              {OCList?.map((x, i) => (
                <OtherChargeRow
                  key={i}
                  formikProps={formikProps}
                  handleOCListChange={handleOCListChange}
                  i={i}
                  x={x}
                />
              ))}
              <tr>
                <td
                  className="text-right"
                  colSpan={7}
                  style={{ borderTop: 'none', padding: '5px 0px' }}
                >
                  <div className="total-background">
                    <label style={{ fontSize: '1.1rem' }}>
                      <span style={{ marginRight: 6 }}>Total</span>
                      <span style={{ marginRight: 6 }}>:</span>

                      <span>₹ {handleTotalOtherCharge()}</span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* ) : undefined} */}
          </table>
        </div>
      </div>
    </div>
  );
};

export default OtherCharges;
