
import {
  FilterActionTypes,
  SET_LEADS_FILTER,
  SET_SOLD_BY_SOURCE_FILTER,
  SET_SOLD_UNITS_FILTER,
} from './actions';

export interface FilterState {
  filter: string;
}

const initialFilterState: FilterState = {
  filter: 'current',
};

export const leadsFilterReducer = (state :FilterState = initialFilterState, action: FilterActionTypes) => {
  switch (action.type) {
    case SET_LEADS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state || initialFilterState;
  }
};

export const soldUnitsFilterReducer = (state :FilterState= initialFilterState, action: FilterActionTypes) => {
  switch (action.type) {
    case SET_SOLD_UNITS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state || initialFilterState;
  }
};

export const soldBySourceFilterReducer = (
  state :FilterState= initialFilterState,
  action: FilterActionTypes,
) => {
  switch (action.type) {
    case SET_SOLD_BY_SOURCE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state || initialFilterState;
  }
};
