import { styled, Typography } from '@mui/material';

const Label = styled(Typography)`
  color: #53575b;
  font-weight: 400;
  font-size: 18px;
`;
const Value = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  color: #041d36;
`;
const OverallDiscount = props => {
  const { formikProps, handleTotalOtherDiscountAmt } = props;
  const { values, handleChange } = formikProps;
  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-6">
        <h5>Overall Discount</h5>

        <div className="form-row">
          <div className="col">
            <div style={{ display: 'flex', gap: '3rem', alignItems: 'center' }}>
              <Label>Total Discount</Label>
              <Value>
                :
                <span>
                  {' '}
                  {(
                    parseFloat(handleTotalOtherDiscountAmt()) + Number(values.basic_rate_disc_amt)
                  ).toFixed(2)}
                </span>
              </Value>
            </div>
          </div>
        </div>

        <div className="form-row mt-3">
          <div className="form-group col">
            <label>Discount remark</label>
            <textarea
              className="form-control"
              cols={100}
              name="disc_remarks"
              rows={10}
              style={{ height: 'unset' }}
              value={values.disc_remarks}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallDiscount;
