import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Box, Button, IconButton, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import { toast } from 'react-toastify';
import { sendBookingFormOTP } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

import OTPDialog from './OTPDialog';
const PageHeader = styled(AppBar)`
  background: #ffffff;
  box-shadow: none;
  color: #041d36;
  align-items: center;
  padding: 10px 20px 10px 40px;
  justify-content: space-between;
  border-bottom: 0.5px solid #d7d3d373;
  margin-top: 10px;
`;

const SaveButton = styled(Button)`
  && {
    background-color: #4872f4;
    color: #f6f7fb;
    border-radius: 12px;
    padding: 12px 36px 12px 36px;
    text-transform: none;
    &:hover {
      background: #4872f4;
    }
    &:disabled {
      background: #e7e7e7;
      color: #8e8e8e;
    }
  }
`;
const Timer = ({ minutes, seconds }) => (
  <div>
    <p style={{ color: '#868686' }}>
      Time Left:{' '}
      <strong id="minutesshow" style={{ color: '#041D36' }}>
        {minutes} : {seconds}
      </strong>
    </p>
  </div>
);

const BookingHeader = props => {
  const {
    navigate,
    unit_id,
    projectUnitStatus,
    formikProps,
    EDIT,
    updateStatusToAvailable,
    customerDetails,
    brokerDetails,
    project_id,
  } = props;

  const { errors, setFieldValue, handleSubmit } = formikProps;
  const dispatch = useAppDispatch();
  const [isOTPSubmission, setOTPSubmission] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // filtering units for time
  const unitTimerData = useMemo(() => {
    const _timerData = projectUnitStatus?.find(e => e.id === Number(unit_id));
    return _timerData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUnitStatus]);

  // timer calculations
  const loggedTime = unitTimerData?.tmp_booking_time_start;
  const updatedTime = dayjs().diff(loggedTime, 'milliseconds');

  useEffect(() => {
    if (loggedTime > 1800000) {
      localStorage.setItem('bookingTimer', JSON.stringify(dayjs()));
    }
  }, [loggedTime]);

  const remainingTime = Number.isNaN(updatedTime) ? 0 : updatedTime;
  const currentTime = Date.now() + 1800000 - remainingTime;

  // handling validations
  const validationErrors = useMemo(() => {
    const parsed = [];

    Object?.values(errors)?.map(error => {
      if (Array.isArray(error)) {
        error.map(err => {
          if (err) {
            Object?.values(err)?.map(v => {
              if (v && !parsed.includes(v)) {
                parsed.push(v);
              }
            });
          }
        });
      } else {
        if (error && !parsed.includes(error)) {
          parsed.push(error);
        }
      }
    });
    return parsed;
  }, [errors]);

  // redux state values
  const { booking_validation, booking_otp } = useAppSelector(s => s.sales);

  const SAVE_WITH_OTP_SWITCH = booking_validation?.form2_otp_on_off === 'yes' ? true : false;
  const SAVE_WITH_EMAIL_OTP = booking_validation?.form2_email_otp_on_off === 'yes' ? true : false;
  const BROKER_OTP_SWITCH = booking_validation?.broker_otp_switch_on_off === 'yes' ? true : false;

  const toggleDialog = () => {
    if (customerDetails?.phone) {
      dispatch(
        sendBookingFormOTP({
          project_id,
          customer_phone: SAVE_WITH_OTP_SWITCH ? customerDetails?.phone : 0,
          broker_phone: BROKER_OTP_SWITCH ? brokerDetails?.phone : '',
          customer_email: SAVE_WITH_EMAIL_OTP ? customerDetails?.email : '',
          broker_email: BROKER_OTP_SWITCH ? brokerDetails?.email : '',
        }),
      );
    }
    setOTPSubmission(v => !v);
  };

  const closeDialog = () => {
    setOTPSubmission(false);
  };

  const verifyFormOTP = async (mobileOTP = 0, emailOTP = 0) => {
    const { email_otp, phone_otp } = booking_otp;

    if (SAVE_WITH_OTP_SWITCH) {
      if (Number(mobileOTP) !== Number(phone_otp)) {
        toast.error('Mobile OTP does not match, Please try again!');
        return;
      }
    }

    if (SAVE_WITH_EMAIL_OTP) {
      if (Number(emailOTP) !== Number(email_otp)) {
        toast.error('Email OTP does not match, Please try again!');
        return;
      }
    }

    setFieldValue('is_verified_otp', 'yes');
    setFieldValue('is_verified_email_otp', 'yes');
    setIsSubmitting(true);
    await handleSubmit();
    setIsSubmitting(false);
  };
  const handleCancel = async () => {
    if (EDIT) {
      Cookies.set('_hjpreviewUnitUser_2492391', '');
      Cookies.set('_hjpreviewtower_idUser_2492391', '');
      Cookies.set('_hjbookingidUser_2492391', '');
      Cookies.set('_hjpreviewproject_floorUser_2492391', '');
      navigate(-1);

      return;
    }
    updateStatusToAvailable();
  };
  const handleBack = () => {
    if (EDIT) {
      Cookies.set('_hjpreviewUnitUser_2492391', '');
      Cookies.set('_hjpreviewtower_idUser_2492391', '');
      Cookies.set('_hjbookingidUser_2492391', '');
      Cookies.set('_hjpreviewproject_floorUser_2492391', '');
      navigate(-1);

      return;
    }
    navigate(-1);
  };
  const validatioErrorMessageCheck = validationErrors?.length > 0;
  return (
    <PageHeader position="sticky">
      <Box className="d-flex align-item-center justify-content-between w-100">
        <Box className="d-flex align-item-center">
          <IconButton style={{ backgroundColor: '#e5eafa', color: '#4872f4' }} onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography style={{ marginLeft: 12, paddingLeft: 10 }} variant="h5">
            Booking Form
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!EDIT ? (
            <div style={{ marginTop: '20px' }}>
              <Countdown
                date={currentTime}
                renderer={props => <Timer {...props} />}
                onComplete={async () => {
                  localStorage.clear();
                  window.location.replace(process.env.REACT_APP_REDIRECT_URL);
                }}
              />
            </div>
          ) : undefined}

          <button
            className="Btn btn-lightblue-primary lbps-btn ml-4"
            data-dismiss="modal"
            type="button"
            onClick={handleCancel}
          >
            Cancel
          </button>

          {(SAVE_WITH_OTP_SWITCH || SAVE_WITH_EMAIL_OTP) && (
            <SaveButton
              className="ml-4"
              type="button"
              onClick={() => {
                toggleDialog();
              }}
            >
              {'Save With OTP'}
            </SaveButton>
          )}
          <SaveButton
            className="ml-4"
            disabled={
              validatioErrorMessageCheck
                ? undefined
                : isSubmitting || SAVE_WITH_OTP_SWITCH || SAVE_WITH_EMAIL_OTP
            }
            type="button"
            onClick={() => {
              setIsSubmitting(true);
              handleSubmit();
            }}
          >
            {EDIT ? 'Update' : 'Submit For Approval'}
          </SaveButton>
          {isOTPSubmission ? (
            <OTPDialog
              closeDialog={closeDialog}
              customerDetails={customerDetails}
              emailOTPReq={SAVE_WITH_EMAIL_OTP}
              isOTPSubmission={isOTPSubmission}
              mobileOTPReq={SAVE_WITH_OTP_SWITCH}
              toggleDialog={toggleDialog}
              verifyFormOTP={verifyFormOTP}
            />
          ) : null}
        </Box>
      </Box>

      {/* error box */}
      <Box className="w-100 mt-2 mx-4">
        {validationErrors.length ? (
          <Box
            sx={{
              background: 'rgba(255, 93, 93, 0.1)',
              borderRadius: 2,
              padding: `5px`,
              fontSize: '1rem',
            }}
          >
            <ul>
              {validationErrors?.map((err, index) => {
                return (
                  <li key={index}>
                    <Typography color={'#ff5d5d'} variant="h6">
                      {JSON.parse(JSON.stringify(err))}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Box>
        ) : undefined}
      </Box>
    </PageHeader>
  );
};

export default BookingHeader;
