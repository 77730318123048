const CustomLinkInstallmentRow = props => {
  const { e, i, handlePaymentScheduleUpdate } = props;

  return (
    <tr key={`${i}_${e.id}`}>
      <td>{i + 1}</td>
      <td>
        <input
          className="form-control"
          disabled={!e.custom}
          name={`e.${i}.clp_payment_installment`}
          value={e.clp_payment_installment}
          onChange={x => {
            handlePaymentScheduleUpdate(i, 'clp_payment_installment', x.target.value);
          }}
        />
      </td>
      <td>
        {!e.lastRow && (
          <input
            readOnly
            className="form-control"
            name={`e.${i}.clp_per`}
            type="number"
            value={e.clp_per}
            onChange={x => {
              handlePaymentScheduleUpdate(i, 'clp_per', x.target.value);
            }}
          />
        )}
      </td>
      <td>
        {!e.lastRow && (
          <input
            readOnly
            className="form-control"
            name={`e.${i}.clp_basic_amt`}
            type="number"
            value={e.clp_basic_amt !== 0 ? e.clp_basic_amt.toFixed(2) : e.clp_basic_amt}
          />
        )}
      </td>
      <td>
        <input
          readOnly
          className="form-control"
          name={`e.${i}.clp_otherchages_amt`}
          type="number"
          value={
            e.clp_otherchages_amt !== 0 ? e.clp_otherchages_amt.toFixed(2) : e.clp_otherchages_amt
          }
        />
      </td>
      <td>
        <input
          readOnly
          className="form-control"
          name={`e.${i}.clp_gst`}
          type="number"
          value={e.clp_gst !== 0 ? e.clp_gst.toFixed(2) : e.clp_gst}
        />
      </td>
      <td>
        <input
          readOnly
          className="form-control"
          name={`e.${i}.clp_extrachages_amt`}
          type="number"
          value={
            e.clp_extrachages_amt !== 0 ? e.clp_extrachages_amt.toFixed(2) : e.clp_extrachages_amt
          }
        />
      </td>
      <td>
        <input
          readOnly
          className="form-control"
          name={`e.${i}.clp_installment_amount`}
          type="number"
          value={
            e.clp_installment_amount !== 0
              ? e.clp_installment_amount.toFixed(2)
              : e.clp_installment_amount
          }
        />
      </td>
      {/* <td>
        {!e.lastRow ? (
          <button
            className="add-comp-btn m-0 acount-act-btn red-common"
            type="button"
            onClick={() => handleDeleteInstallment(i)}
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 6 8"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.498698 6.91667C0.498698 7.375 0.873698 7.75 1.33203 7.75H4.66537C5.1237 7.75 5.4987 7.375 5.4987 6.91667V1.91667H0.498698V6.91667ZM5.91537 0.666667H4.45703L4.04036 0.25H1.95703L1.54036 0.666667H0.0820312V1.5H5.91537V0.666667Z"
                fill="#FF5D5D"
              ></path>
            </svg>
          </button>
        ) : undefined}
      </td> */}
    </tr>
  );
};

export default CustomLinkInstallmentRow;
