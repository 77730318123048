import { Chip } from '@mui/material';
import { useState } from 'react';

import AddSignatureModal from './AddSignatureModal';

const Signature = props => {
  const [showSignatureModal, setSignatureModal] = useState(false);

  const { formikProps } = props;
  const { values, setFieldValue } = formikProps;

  const toggleSignatureModal = () => {
    setSignatureModal(v => !v);
  };
  const removeSignature = () => {
    setFieldValue('signature', null);
  };

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <div className="form-row mb-4">
          <div>
            <h5>
              Customer Signature <Chip color="success" label="Beta" />
            </h5>
            <AddSignatureModal
              handleClose={toggleSignatureModal}
              show={showSignatureModal}
              {...props}
            />
            <div className="d-flex align-items-center">
              <button
                className="Btn btn-lightblue-primary lbps-btn mr-3"
                type="button"
                onClick={toggleSignatureModal}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <svg
                    fill="none"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 5V19"
                      stroke="#4872F4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      d="M5 12H19"
                      stroke="#4872F4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                  <span style={{ marginTop: 3, marginLeft: 5 }}> Add Signature</span>
                </div>
              </button>
              {values?.signature ? (
                <div>
                  <button
                    className="add-comp-btn m-0 acount-act-btn red-common"
                    style={{ height: 40, width: 40 }}
                    type="button"
                    onClick={removeSignature}
                  >
                    <svg
                      fill="none"
                      height="20"
                      viewBox="0 0 6 8"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.498698 6.91667C0.498698 7.375 0.873698 7.75 1.33203 7.75H4.66537C5.1237 7.75 5.4987 7.375 5.4987 6.91667V1.91667H0.498698V6.91667ZM5.91537 0.666667H4.45703L4.04036 0.25H1.95703L1.54036 0.666667H0.0820312V1.5H5.91537V0.666667Z"
                        fill="#FF5D5D"
                      ></path>
                    </svg>
                  </button>
                </div>
              ) : undefined}
            </div>
            {values?.signature ? (
              <div className="my-4 sigImgWrapper">
                <img className="sigImage" src={values.signature} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signature;
