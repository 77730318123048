import { Cancel as CancelIcon } from '@mui/icons-material';
import { Button, Divider, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import Select from 'react-select';
import { updateApprovalFilters } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { DEFAULT_BOOKING_APPROVAL_FILTERS, getUniqueOptions } from 'utils/constant';

const ApplyBtn = styled(Button)`
  background: rgba(72, 114, 244, 0.1);
  color: #4872f4;
  margin: 5px 10px 0;
  padding: 4px 15px;
  font-size: 16px;
  transition: background-color 0.3s;
  border-radius: 8px;
  box-shadow: none;
  text-transform: none;
  &:hover {
    color: #fff;
    background-color: #4872f4;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }
`;

const ClearBtn = styled(Button)`
  background: rgba(255, 93, 93, 0.2);
  color: #ff5d5d;
  margin: 5px 0 0 10px;
  padding: 4px 15px;
  font-size: 16px;
  transition: background-color 0.5s;
  border-radius: 8px;
  box-shadow: none;
  text-transform: none;
  border: #ff5d5d;
  &:hover {
    background-color: #ff5d5d;
    color: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }
`;

const ApprovalFilters = props => {
  const { toggleDrawer, open, processedBookingApprovals } = props;
  const { booking_approval_filter } = useAppSelector(s => s.sales);

  const dispatch = useAppDispatch();

  const FILTER_STATUS = [
    { value: 'pending', label: 'Pending' },
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'deleted', label: 'Deleted' },
  ];

  const EvaluatedByOptions = useMemo(() => {
    const listOptions = getUniqueOptions(
      processedBookingApprovals
        ?.filter(approval => approval.evaluated_by !== ' ')
        ?.map(v => ({
          value: v.evaluated_by,
          label: v.evaluated_by,
        })),
    );

    return listOptions;
  }, [processedBookingApprovals]);

  const onSubmit = async () => {
    console.log("Selected values:", values);
    await dispatch(updateApprovalFilters(values));
    toggleDrawer();
  };

  const clearForm = () => {
    dispatch(updateApprovalFilters(DEFAULT_BOOKING_APPROVAL_FILTERS));
    toggleDrawer();
  };

  const formikProps = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: booking_approval_filter,
    onSubmit,
  });

  const { setFieldValue, values } = formikProps;

  const handleValueChange = (key, value) => {
    setFieldValue(key, Array.isArray(value) && value?.length ? value : undefined);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: '12px',
        },
      }}
      onClose={toggleDrawer}
    >
      <div
        style={{
          height: '48px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px 15px',
          marginBottom: '10px',
        }}
      >
        <p>Approval Filters</p>
        <CancelIcon
          sx={{
            backgroundColor: '#FFE1E1',
            borderRadius: '80px',
            height: '26.67px',
            width: '26.67px',
            color: '#FF5D5D',
            cursor: 'pointer',
          }}
          onClick={toggleDrawer}
        />
      </div>
      <Divider sx={{ mt: 2 }} />
      <div style={{ width: '388px' }}>
        <div style={{ padding: '0 15px', gap: '16px' }}>
          <div className="d-flex justify-content-end">
            <ApplyBtn onClick={onSubmit}>Apply</ApplyBtn>
            <ClearBtn onClick={clearForm}>Clear</ClearBtn>
          </div>
          <div className="my-3">
            <Typography className="muted-text" variant="caption">
              Status
            </Typography>
            <Select
              closeMenuOnSelect={true}
              defaultValue={FILTER_STATUS.find(v => v.value === values.status)}
              name="status"
              options={FILTER_STATUS}
              placeholder="Select Status"
              styles={{
                container: base => ({
                  ...base,
                }),
              }}
              onChange={v => setFieldValue('status', v.value)}
            />
          </div>
          <div className="my-3">
            <Typography className="muted-text" variant="caption">
              Evaluated by
            </Typography>
            <Select
              isMulti
              closeMenuOnSelect={false}
              defaultValue={EvaluatedByOptions?.filter(v => values.evaluated_by?.includes(v))}
              name="evaluated_by"
              options={EvaluatedByOptions}
              placeholder="Select Person"
              styles={{
                container: base => ({
                  ...base,
                }),
              }}
              onChange={v => handleValueChange('evaluated_by', v)}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ApprovalFilters;
