export const SET_LEADS_FILTER = 'SET_LEADS_FILTER';
export const SET_SOLD_UNITS_FILTER = 'SET_SOLD_UNITS_FILTER';
export const SET_SOLD_BY_SOURCE_FILTER = 'SET_SOLD_BY_SOURCE_FILTER';

export type FilterActionTypes =
  | ReturnType<typeof setLeadsFilter>
  | ReturnType<typeof setSoldUnitsFilter>
  | ReturnType<typeof setSoldBySourceFilter>;

export const setLeadsFilter = (filter: string) => ({
  type: SET_LEADS_FILTER,
  payload: filter,
});

export const setSoldUnitsFilter = (filter: string) => ({
  type: SET_SOLD_UNITS_FILTER,
  payload: filter,
});

export const setSoldBySourceFilter = (filter: string) => ({
  type: SET_SOLD_BY_SOURCE_FILTER,
  payload: filter,
});
