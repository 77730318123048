import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';

const RejectBtn = styled(Button)`
  background: rgba(255, 93, 93, 0.2);
  color: #ff5d5d;
  margin: 0 20px 0 10px;
  padding: 11px 24px;
  font-size: 14 px;
  transition: background-color 0.5s;
  border-radius: 8px;
  box-shadow: none;
  text-transform: none;
  &:hover {
    background-color: #ff5d5d;
    color: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }
`;

const SaveButton = styled(Button)`
  && {
    background-color: #4872f4;
    color: #f6f7fb;
    border-radius: 8px;
    padding: 11px 24px;
    text-transform: none;
    font-size: 14px;

    &:hover {
      background: #4872f4;
    }
    &:disabled {
      background: #e7e7e7;
      color: #8e8e8e;
    }
  }
`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide ref={ref} direction="up" {...props} />;
});

const OTPDialog = props => {
  const { isOTPSubmission, toggleDialog, verifyFormOTP, customerDetails } = props;
  const [otp, setOtp] = useState<any>();

  return (
    <Dialog
      fullWidth
      aria-labelledby="send-for-approval-with-otp"
      maxWidth={'sm'}
      open={isOTPSubmission}
      TransitionComponent={Transition}
      onClose={null}
    >
      <DialogTitle align="center">Send for Approval with OTP</DialogTitle>
      {customerDetails?.phone ? (
        <Alert severity="success">
          A Verification OTP has been sent to {`+91-${customerDetails?.phone}`}{' '}
        </Alert>
      ) : (
        <Alert severity="error">Please Select Customer First!</Alert>
      )}
      <DialogContent className="shwan-form">
        <input
          className="form-control shadow-none"
          type="number"
          value={otp}
          onChange={v => setOtp(v.target.valueAsNumber)}
        />
      </DialogContent>
      <DialogActions>
        <SaveButton disabled={!customerDetails?.phone} onClick={() => verifyFormOTP(otp)}>
          Send
        </SaveButton>
        <RejectBtn onClick={toggleDialog}>Cancel</RejectBtn>
      </DialogActions>
    </Dialog>
  );
};

export default OTPDialog;
