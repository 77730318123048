import './CreditNotes.css';

import { Close, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, IconButton, styled, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Loader from 'components/atoms/Loader';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { CREDIT_NOTES_STATUS } from 'utils/constant';
import { getCreditNotesList } from 'redux/materials';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getPermissions } from 'utils';
import { CN_STATUS, PERMISSIONS_BASE_STRUCTURE, rowsPerPage } from 'utils/constant';

const HeadingFont = styled(Typography)`
  && {
    font-size: 28px;
    weight: 600;
  }
`;

const CreditNotesTable = props => {
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCNList, setSearchCNList] = useState([]);
  const navigate = useNavigate();
  const { item } = props;

  const handleChangePage = event => {
    const newPage = parseInt(event.currentTarget.value, 10) - 1;
    setPage(newPage);
  };

  const navtoCreditNotesPreview = id => {
    navigate(`/CreditNotesPreview`, {
      state: { id },
    });
  };

  useEffect(() => {
    setSearchCNList(item);
  }, [item]);

  const handleSearchButtonClick = () => {
    if (!searchTerm) {
      setSearchCNList(item);
    } else {
      const filteredData = item?.filter(cnListRow => {
        if (!searchTerm) return true;
        const formattedCreditDate = dayjs(cnListRow?.credit_date).format('DD-MM-YYYY');
        return (
          cnListRow?.credit_note?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          cnListRow?.invoice_no?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          cnListRow?.supplier_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          formattedCreditDate?.includes(searchTerm) ||
          cnListRow?.amount.toString().includes(searchTerm) ||
          cnListRow?.balance.toString().includes(searchTerm) ||
          CN_STATUS[cnListRow?.status]?.label?.toLowerCase() === searchTerm?.toLowerCase()
        );
      });
      setSearchCNList(filteredData);
      setPage(0);
    }
  };

  const handleClearButton = () => {
    setSearchTerm('');
    setSearchCNList(item);
  };
  return (
    <div style={styles.tableContainer}>
      <div className="d-flex align-items-center mb-3">
        <Box
          noValidate
          autoComplete="off"
          className="shwan-form"
          component="form"
          sx={{
            '& > :not(style)': { width: '45ch' },
          }}
        >
          <div className="position-relative">
            <div className="d-flex align-items-center">
              <input
                className="form-control"
                placeholder="Search Here"
                style={{ height: '48px' }}
                value={searchTerm}
                onChange={event => setSearchTerm(event.target.value)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSearchButtonClick();
                  }
                }}
              />
              <div style={{ display: 'flex', position: 'absolute', right: 0, marginRight: 16 }}>
                {searchTerm ? (
                  <Close sx={{ color: '#707c8a' }} onClick={handleClearButton} />
                ) : null}
                <Divider flexItem className="mx-3" orientation="vertical" />
                <Search sx={{ color: '#707c8a' }} onClick={handleSearchButtonClick} />
              </div>
            </div>
          </div>
        </Box>
      </div>
      <Box>
        <div style={styles.tablePaper}>
          <table className="table m-0 table-hover w-100 border rounded">
            <thead className="tbl-header">
              <tr className="sticky-header" role="row" style={{ top: '-1px' }}>
                <th style={{ width: 150 }}>Date</th>
                <th>Credits Notes</th>
                <th>Invoice Number</th>
                <th>Supplier Name</th>
                <th style={{ textAlign: 'center' }}>Status</th>
                <th style={{ textAlign: 'center' }}>Amount</th>
                <th style={{ textAlign: 'center' }}>Balance</th>
              </tr>
            </thead>
            <tbody>
              {searchCNList?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)?.map(row => (
                <tr
                  key={row.id}
                  role="row"
                  onClick={() => {
                    navtoCreditNotesPreview(row.id);
                  }}
                >
                  <td>{dayjs(row.credit_date).format('DD-MM-YYYY') || '--'}</td>
                  <td>{row.credit_note || '--'}</td>
                  <td>
                    {row.invoice_no || '--'}
                    {/* {row.supplier_id} */}
                  </td>
                  <td>{row.supplier_name || '--'}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Typography
                      style={{
                        ...styles.values,
                        color: CN_STATUS[row.status]?.color,
                      }}
                    >
                      {CN_STATUS[row.status]?.label}
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center' }}>₹{row.amount || 0}</td>
                  <td style={{ textAlign: 'center' }}>₹{row.balance || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between align-items-center mx-3 mt-1">
          <div className="d-flex align-items-center">
            <Typography style={{ color: '#6c757d', fontWeight: 500, fontSize: 16 }} variant="body2">
              Showing
            </Typography>
            <Typography
              style={{ color: '#6c757d', margin: '0 5px', fontWeight: 'bold', fontSize: '1.3rem' }}
              variant="body1"
            >
              <span>{`${page * rowsPerPage + 1}-${Math.min(
                (page + 1) * rowsPerPage,
                item?.length || 0,
              )} of ${item?.length || 0}`}</span>
            </Typography>
            <Typography style={{ color: '#6c757d', fontWeight: 500, fontSize: 16 }} variant="body2">
              results
            </Typography>
          </div>
          <div className="pagination-select">
            <select
              className="form-control"
              style={{ width: '100px' }}
              value={page + 1}
              onChange={event => handleChangePage(event)}
            >
              {Array?.from({ length: Math?.ceil(item?.length / rowsPerPage) }, (_, i) => i + 1).map(
                pageNumber => (
                  <option key={pageNumber} value={pageNumber}>
                    {pageNumber}
                  </option>
                ),
              )}
            </select>
          </div>
        </div>
      </Box>
    </div>
  );
};

const CreditNotesList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { creditNotesList, loading } = useAppSelector(s => s.materials);
  const { loading: projectLoading, modulesData } = useAppSelector(s => s.project);

  const [modulePermission, setModulePermission] = useState(PERMISSIONS_BASE_STRUCTURE);

  // url params
  // const project_id = searchParams.get('project_id');
  // const token = searchParams.get('token');

  const project_id = Cookies.get('_hjproject_idUser_2492391');
  // const project_id = '17';

  useEffect(() => {
    setModulePermission(getPermissions('Credit Notes'));
  }, [modulesData]);

  useEffect(() => {
    dispatch(
      getCreditNotesList({
        project_id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const navtoCreateNotes = () => {
    navigate(`/createCreditNotes`);
  };

  return (
    <>
      <Loader loading={loading && projectLoading} />
      <div style={{ background: '#fff' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
            marginBottom: '5px',
            alignItems: 'center',
            top: '0',
            backgroundColor: '#fff',
            zIndex: 50,
          }}
        >
          <div style={styles.mainHeading}>
            <IconButton
              style={{ backgroundColor: '#e5eafa', color: '#4872f4', marginRight: '20px' }}
              onClick={handleBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <HeadingFont>Credit Notes</HeadingFont>
          </div>
          {modulePermission?.add ||
          modulePermission?.editor ||
          modulePermission?.approval ||
          modulePermission?.admin ? (
            <Button
              disableElevation={true}
              style={{
                background: '#4872F4',
                borderRadius: '12px',
                padding: '9px 24px 9px 24px',
                textTransform: 'none',
                fontSize: '14px',
                marginRight: '20px',
              }}
              variant="contained"
              onClick={navtoCreateNotes}
            >
              <AddIcon style={{ fontSize: 'medium' }} sx={{ mr: 0.5 }} /> Create New
            </Button>
          ) : undefined}
        </div>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ paddingRight: '20px', marginTop: '10px' }}>
          <CreditNotesTable item={creditNotesList} />
        </Box>
      </div>
    </>
  );
};

const styles = {
  mainHeading: {
    marginLeft: '20px',
    marginBottom: '10px',
    marginTop: '10px',
    display: 'flex',
  },

  tableContainer: {
    marginLeft: '20px',
    marginTop: '20px',
  },

  values: {
    color: '#23394F',
    fontWeight: '500',
  },

  navigationButton: {
    backgroundColor: '#e5eafa',
    padding: '2px 5px',
    borderRadius: '5px',
    marginRight: '5px',
  },
  tablePaper: {
    // height: '100vh',
    height: 'calc(100vh - 265px)',
    overflow: 'auto',
  },
};
export default CreditNotesList;
