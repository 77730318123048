import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addCreditBills, getCreditBillsList, getListInvoice } from 'redux/materials';
import { useAppDispatch } from 'redux/store';
import theme from 'Theme';
import * as Yup from 'yup';

import BillRow from './Components/BillRow';

const CancleButton = styled(Button)`
  && {
    background-color: #edf1fe;
    color: #4872f4;
    border-radius: 12px;
    margin-right: 10px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    &:hover {
      background: edf1fe;
    }
  }
`;

const SaveButton = styled(Button)`
  && {
    background-color: #4872f4;
    color: #f6f7fb;
    border-radius: 12px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    &:hover {
      background: #4872f4;
    }
    &:disabled {
      background: #e7e7e7;
    }
  }
`;

const AddMoreBills = styled(Button)`
  && {
    background-color: #edf1fe;
    color: #4872f4;
    border-radius: 12px;
    margin-right: 10px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    &:hover {
      background: edf1fe;
    }
  }
`;

const HeadingFont = styled(Typography)`
  && {
    font-size: 28px;
    weight: 600;
  }
`;

const TableRowFonts = styled(Typography)`
  && {
    font-size: 18px;
    weight: 500;
  }
`;

const StyleTableCell = styled(TableCell)(() => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'unset',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '4px',
      position: 'relative',
      '&:not(&:nth-child(1) )': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 20,
          left: 0,
          bottom: 20,
          width: '1px',
          background: `${theme.palette.grey[300]}`,
        },
      },
    },
    [`&.${tableCellClasses.body}`]: {
      border: '0px 0px 2px 0px',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      position: 'relative',
    },
  };
});

const SelectCredits = props => {
  const { formikProps, creditRemaining } = props;

  const { values, setFieldValue } = formikProps;

  const initialBill = {
    invoice_id: 0,
    billDate: '',
    billAmount: 0,
    billBalance: 0,
    credit_amount: 0,
  };

  const handleBillAdd = () => {
    const updatedBills = [...values.bills];
    updatedBills.push(initialBill);

    setFieldValue('bills', updatedBills);
  };

  const handleBillUpdate = (index, field, value) => {
    const updatedBills = [...values.bills];
    updatedBills[index][field] = value;

    setFieldValue('bills', updatedBills);
  };

  const handleBillRemove = (index: number) => {
    //ownership from booking form
    const updatedBills = [...values.bills];
    updatedBills.splice(index, 1);
    setFieldValue('bills', updatedBills);
  };

  return (
    <Paper
      elevation={0}
      style={{
        marginTop: 15,
        marginLeft: 20,
        marginRight: '20px',
        padding: '16px 32px 24px 32px ',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '20px', color: '#3D3F43', mt: 1, mb: 1 }}>
          Select Invoice
        </Typography>
        <Box className="px-3 py-2" style={{ background: '#DBF4C7', borderRadius: '6px' }}>
          <Typography
            className="font-weight-bold"
            sx={{ color: '#497D2E', fontSize: '18px', textAlign: 'center' }}
          >
            Credit Remaining: {`₹ ${creditRemaining || ''}`}
          </Typography>
        </Box>
      </div>
      <Divider className="mt-3" />
      <div>
        <TableContainer>
          <Table
            aria-label="simple table"
            sx={{ minWidth: 650, borderRadius: '4px', mt: 1, mb: 2 }}
          >
            <TableHead>
              <TableRow>
                <StyleTableCell sx={{ padding: '0px' }} width={'20%'}>
                  <TableRowFonts>Invoice</TableRowFonts>
                </StyleTableCell>
                <StyleTableCell sx={{ fontWeight: '500', fontSize: '18px' }} width={'15%'}>
                  <TableRowFonts>Date</TableRowFonts>
                </StyleTableCell>
                <StyleTableCell width={'15%'}>
                  <TableRowFonts>Inv. Amount</TableRowFonts>
                </StyleTableCell>
                <StyleTableCell width={'15%'}>
                  <TableRowFonts>Inv. Balance</TableRowFonts>
                </StyleTableCell>
                <StyleTableCell width={'15%'}>
                  <TableRowFonts sx={{ fontWeight: '500', fontSize: '18px' }}>
                    Credit To Apply
                  </TableRowFonts>
                </StyleTableCell>
                <StyleTableCell width={'5%'}>
                  <TableRowFonts>Actions</TableRowFonts>
                </StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.bills.length
                ? values?.bills?.map((bill, index) => {
                    return (
                      <BillRow
                        key={index}
                        bill={bill}
                        handleBillRemove={handleBillRemove}
                        handleBillUpdate={handleBillUpdate}
                        index={index}
                      />
                    );
                  })
                : undefined}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <AddMoreBills sx={{ mb: 2 }} onClick={handleBillAdd}>
          Add More Bills
        </AddMoreBills>
      </div>
    </Paper>
  );
};

const ApplyCredits = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    project_id,
    supplier_id,
    credit_notes_id,
    credit_remaining: creditRemaining,
  } = location.state || {};

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(
      getCreditBillsList({
        project_id,
      }),
    );
    dispatch(
      getListInvoice({
        project_id,
        supplier_id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    bills: [],
    credit_notes_id: 0,
    supplier_id: 0,
    created: dayjs().format('YYYY-MM-DD'),
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    const { bills } = values;

    setTimeout(async () => {
      const res: any = await dispatch(
        addCreditBills({
          project_id,
          bills,
          supplier_id,
          credit_notes_id,
        }),
      );

      if (!res?.error) {
        navigate(-1);
      }

      await setSubmitting(false);
    }, 400);
  };

  const validate = () => {
    const errors: any = {};

    const billCredit = formik.values.bills.reduce(
      (total, bill) => total + parseFloat(bill.credit_amount || 0),
      0,
    );

    if (billCredit > creditRemaining) {
      errors.extraErrors = 'Credit Amount cannot be more than Remaining Credit';
    }

    return errors;
  };

  const validationSchema = Yup.object({
    bills: Yup.array().of(
      Yup.object().shape({
        credit_amount: Yup.number().max(
          Yup.ref('bill_balance'),
          'Credit Amount cannot be more than Bill Balance Amount',
        ),
      }),
    ),
  });

  const formik = useFormik({
    initialValues,
    validate,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: handleFormSubmit,
  });

  const { handleSubmit, errors, isSubmitting } = formik;

  // handling validations
  const validationErrors = useMemo(() => {
    const parsed = [];

    Object?.values(errors)?.map(error => {
      if (Array.isArray(error)) {
        error.map(err => {
          if (err) {
            Object?.values(err)?.map(v => {
              if (v && !parsed.includes(v)) {
                parsed.push(v);
              }
            });
          }
        });
      } else {
        if (error && !parsed.includes(error)) {
          parsed.push(error);
        }
      }
    });
    return parsed;
  }, [errors]);

  return (
    <>
      <div
        style={{
          background: '#f6f7fb',
          zIndex: 50,
          height: '75px',
          position: 'sticky',
          top: 0,
          paddingTop: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={styles.mainHeading}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton style={{ color: '#041D36' }} onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <HeadingFont style={{ marginLeft: '5px' }}>Apply Credits</HeadingFont>
            </div>
          </div>
          <div style={styles.topBtn}>
            <CancleButton
              disableElevation={true}
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleBack}
            >
              Cancel
            </CancleButton>
            <SaveButton
              disabled={isSubmitting}
              disableElevation={true}
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Save
            </SaveButton>
          </div>
        </div>
        <Divider sx={{ mt: 2 }} />
      </div>
      {/* error box */}
      <Box className="w-90 mt-2 mx-4">
        {validationErrors.length ? (
          <Box
            sx={{
              background: 'rgba(255, 93, 93, 0.1)',
              borderRadius: 2,
              padding: `5px`,
              fontSize: '1rem',
            }}
          >
            <ul>
              {validationErrors?.map((err, index) => {
                return (
                  <li key={index}>
                    <Typography color={'#ff5d5d'} variant="h6">
                      {JSON.parse(JSON.stringify(err))}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Box>
        ) : undefined}
      </Box>
      <SelectCredits creditRemaining={creditRemaining} formikProps={formik} />
    </>
  );
};

const styles = {
  mainHeading: {
    marginLeft: '20px',
  },
  topBtn: {
    marginRight: '20px',
  },
};

export default ApplyCredits;
