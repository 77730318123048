import { styled, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useAppSelector } from 'redux/store';

const Label = styled(Typography)`
  color: #53575b;
  font-weight: 400;
  font-size: 18px;
`;

const Value = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #041d36;
`;
const UnitInfo = props => {
  const { formikProps, unit_id } = props;
  const { values, handleBlur, handleChange } = formikProps;

  const { unitInfo, unitAreaInfo } = useAppSelector(s => s.sales);

  // unitInfo
  const unitInfoValues = useMemo(() => {
    return unitInfo?.booking_unit_sheet_towers_data?.find(
      e => e.project_main_units_id === Number(unit_id),
    );
  }, [unitInfo?.booking_unit_sheet_towers_data, unit_id]);

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Unit Information</h5>

        <div className="form-row">
          <div className="form-group col-sm-4 col-md-3 col-lg-4">
            <label>Unit Reservation Date</label>
            <input
              className="form-control"
              name="unit_reserved_date"
              type="date"
              value={values.unit_reserved_date}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-sm-4 col-md-3 col-lg-4  ">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Unit Info</Label>
              <Value style={{ flex: 1 }}>
                : <strong>{unitInfoValues?.title}</strong>
              </Value>
            </div>
          </div>
          <div className="form-group col-sm-4 col-md-3 col-lg-4">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Super Buildup Area </Label>
              <Value style={{ flex: 1 }}>
                : <strong>{unitAreaInfo?.super_build_up_area}</strong>
              </Value>
            </div>
          </div>
          {/* <div className="form-group col-sm-4 col-md-3 col-lg-4">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Car Parking No </Label>
              <Value style={{ flex: 1 }}>
                : <strong>{values.parking_no}</strong>
              </Value>
            </div>
          </div> */}
          <div className="form-group col-sm-4 col-md-3 col-lg-4">
            <div style={{ display: 'flex', gap: '3rem', alignItems: 'center' }}>
              <Label>Terrace Area </Label>
              <Value style={{ flex: 1 }}>
                : <strong>{unitAreaInfo?.terracearea}</strong>
              </Value>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitInfo;
