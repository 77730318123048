import { DISTRIBUTION_METHOD } from 'utils/constant';
const OtherChargeRow = props => {
  const { x, i, handleOCListChange } = props;

  return (
    <>
      <tr key={x.id}>
        <td>{x.other_charges_no}</td>
        <td>{x.other_charges_title}</td>
        <td>
          <select
            className="form-control"
            onChange={e => {
              handleOCListChange(i, 'other_charges_distribution_method', e.target.value);
            }}
          >
            <option disabled selected>
              {isNaN(x.other_charges_distribution_method)
                ? x.other_charges_distribution_method
                : 'Select Distribution Method'}
            </option>
            {DISTRIBUTION_METHOD?.map((e, index) => {
              return (
                <option key={index} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
        </td>
        <td>{x.amount_type === 'ratebase_amt' && <>{x.other_charges_area}</>}</td>
        <td>{x.other_charges_rate}</td>
        <td className="text-right">{x.other_charges_amount || 0}</td>
      </tr>
    </>
  );
};

export default OtherChargeRow;
