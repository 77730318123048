import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as materialService from 'services/material';
import { processError } from 'utils/constant';
import { handleLoading, handleReject } from 'utils/reduxUtils';

import {
  addCreditBillParams,
  CommonParams,
  createCreditParams,
  creditNotesDetailsParams,
  deleteBillParams,
  EditCreditNotesParams,
  ICreditBillsList,
  ICreditNotesDetails,
  ICreditNotesList,
  ICreditNotsLogs,
  IInvoiceMaterials,
  IInvoiceSubMaterials,
  IListInvoice,
  IMaterial,
  IMaterialsState,
  InvoiceListParams,
  invoiceMaterialsParams,
  invoiceSubMaterialsParams,
  ISubMaterial,
  ISupplierList,
  subMaterialParams,
} from './materialsInterface';

export const getCreditNotesList = createAsyncThunk<ICreditNotesList[], CommonParams>(
  'materials/getCreditNotesList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await materialService.getCreditNotesList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getCreditNotesDetails = createAsyncThunk<
  ICreditNotesDetails,
  creditNotesDetailsParams
>('materials/getCreditNotesDetails', async (params, thunkApi) => {
  try {
    const { data: res } = await materialService.getCreditNotesDetails(params);
    return res.data;
  } catch (err) {
    const processedError = processError(err);
    console.log(err);
    return thunkApi.rejectWithValue({ error: processedError });
  }
});

export const addCreditNotes = createAsyncThunk(
  'materials/addCreditNotes',
  async (params: createCreditParams, thunkApi) => {
    try {
      const { data: res } = await materialService.addCreditNotes(params);
      toast.success(res.msg);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      toast.error(processedError);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getSupplierList = createAsyncThunk<ISupplierList[], CommonParams>(
  'materials/getSupplierList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await materialService.getSupplierList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getListInvoice = createAsyncThunk<IListInvoice[], InvoiceListParams>(
  'materials/getListInvoice',
  async (params, thunkApi) => {
    try {
      const { data: res } = await materialService.getListInvoice(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getMaterial = createAsyncThunk<IMaterial[], CommonParams>(
  'materials/getMaterial',
  async (params, thunkApi) => {
    try {
      const { data: res } = await materialService.getMaterial(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getSubMaterial = createAsyncThunk<ISubMaterial[], subMaterialParams>(
  'materials/getSubMaterial',
  async (params, thunkApi) => {
    try {
      const { data: res } = await materialService.getSubMaterial(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getCreditNotsLogs = createAsyncThunk<ICreditNotsLogs[], creditNotesDetailsParams>(
  'materials/getCreditNotsLogs',
  async (params, thunkApi) => {
    try {
      const { data: res } = await materialService.getCreditNotsLogs(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getCreditBillsList = createAsyncThunk<ICreditBillsList[], CommonParams>(
  'materials/getCreditBillsList',
  async (params, thunkApi) => {
    try {
      const { data: res } = await materialService.getCreditBillsList(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const addCreditBills = createAsyncThunk(
  'materials/addCreditBills',
  async (params: addCreditBillParams, thunkApi) => {
    try {
      const { data: res } = await materialService.addCreditBills(params);
      toast.success(res.msg);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      toast.error(processedError);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getInvoicematerials = createAsyncThunk<IInvoiceMaterials[], invoiceMaterialsParams>(
  'materials/getInvoicematerials',
  async (params, thunkApi) => {
    try {
      const { data: res } = await materialService.getInvoicematerials(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

export const getInvoiceSubMaterials = createAsyncThunk<
  IInvoiceSubMaterials[],
  invoiceSubMaterialsParams
>('materials/getInvoiceSubMaterials', async (params, thunkApi) => {
  try {
    const { data: res } = await materialService.getInvoiceSubMaterials(params);
    return res.data;
  } catch (err) {
    const processedError = processError(err);
    console.log(err);
    return thunkApi.rejectWithValue({ error: processedError });
  }
});

export const deleteBill = createAsyncThunk(
  'materials/deleteBill',
  async (params: deleteBillParams, thunkApi) => {
    try {
      const { data: res } = await materialService.deleteBill(params);
      toast.error(res.msg);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      toast.error(processedError);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const deleteCreditNots = createAsyncThunk(
  'materials/deleteCreditNots',
  async (params: creditNotesDetailsParams, thunkApi) => {
    try {
      const { data: res } = await materialService.deleteCreditNots(params);
      toast.error(res.msg);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      toast.error(processedError);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const editCreditNotes = createAsyncThunk(
  'materials/editCreditNotes',
  async (params: EditCreditNotesParams, thunkApi) => {
    try {
      const { data: res } = await materialService.editCreditNotes(params);
      toast.success(res.msg);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      toast.error(processedError);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);

const initialState: IMaterialsState = {
  loading: false,
  creditNotesList: undefined,
  creditNotesDetails: undefined,
  supplierList: [],
  invoiceList: [],
  materialsData: [],
  subMaterialsData: [],
  creditNotesLogs: [],
  creditList: [],
  bills: [],
  invoiceMaterial: [],
  invoiceSubMaterial: [],
  // updatedCreditNotes: [],
};

const materialSlice = createSlice({
  name: 'materials',
  initialState,
  extraReducers: builder => {
    //get credit notes
    builder.addCase(getCreditNotesList.rejected, handleReject);
    builder.addCase(getCreditNotesList.pending, handleLoading);
    builder.addCase(getCreditNotesList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        creditNotesList: action?.payload,
      };
    });
    // credit notes details
    builder.addCase(getCreditNotesDetails.rejected, handleReject);
    builder.addCase(getCreditNotesDetails.pending, handleLoading);
    builder.addCase(getCreditNotesDetails.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        creditNotesDetails: action?.payload,
      };
    });
    // add credit notes
    builder.addCase(addCreditNotes.rejected, handleReject);
    builder.addCase(addCreditNotes.pending, handleLoading);
    builder.addCase(addCreditNotes.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        createCredit: action?.payload,
      };
    });
    //suppliers List
    builder.addCase(getSupplierList.rejected, handleReject);
    builder.addCase(getSupplierList.pending, handleLoading);
    builder.addCase(getSupplierList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        supplierList: action?.payload,
      };
    });
    //invoice List
    builder.addCase(getListInvoice.rejected, handleReject);
    builder.addCase(getListInvoice.pending, handleLoading);
    builder.addCase(getListInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        invoiceList: action?.payload,
      };
    });
    //Material Data
    builder.addCase(getMaterial.rejected, handleReject);
    builder.addCase(getMaterial.pending, handleLoading);
    builder.addCase(getMaterial.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        materialsData: action?.payload,
      };
    });
    //Sub Material Data
    builder.addCase(getSubMaterial.rejected, handleReject);
    builder.addCase(getSubMaterial.pending, handleLoading);
    builder.addCase(getSubMaterial.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        subMaterialsData: action?.payload,
      };
    });
    // Credit Notes Logs
    builder.addCase(getCreditNotsLogs.rejected, handleReject);
    builder.addCase(getCreditNotsLogs.pending, handleLoading);
    builder.addCase(getCreditNotsLogs.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        creditNotesLogs: action?.payload,
      };
    });
    // Credit Bills List
    builder.addCase(getCreditBillsList.rejected, handleReject);
    builder.addCase(getCreditBillsList.pending, handleLoading);
    builder.addCase(getCreditBillsList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        creditList: action?.payload,
      };
    });
    // cn - invoice materials
    builder.addCase(getInvoicematerials.rejected, handleReject);
    builder.addCase(getInvoicematerials.pending, handleLoading);
    builder.addCase(getInvoicematerials.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        invoiceMaterial: action?.payload,
      };
    });
    // cn - invoice sub materials
    builder.addCase(getInvoiceSubMaterials.rejected, handleReject);
    builder.addCase(getInvoiceSubMaterials.pending, handleLoading);
    builder.addCase(getInvoiceSubMaterials.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        invoiceSubMaterial: action?.payload,
      };
    });
    // Edit credit notes
    builder.addCase(editCreditNotes.rejected, handleReject);
    builder.addCase(editCreditNotes.pending, handleLoading);
    builder.addCase(editCreditNotes.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        updatedCreditNotes: action?.payload,
      };
    });
  },
  reducers: undefined,
});

export default materialSlice.reducer;
