import CloseIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useAppSelector } from 'redux/store';
import { config } from 'services/init';
import { BASE_API_URL, DECIMAL_REGEX } from 'utils/constant';

const MaterialRow = ({
  material,
  index,
  handleUpdateMaterialData,
  project_id,
  formikProps,
  handleRemoveMaterial,
  EDIT,
}) => {
  const { material_unit, quantity, rate, total_amount, material_sub_category_id } = material || {};
  const { invoiceMaterial } = useAppSelector(s => s.materials);

  const { handleBlur, errors, values, setFieldValue } = formikProps;

  const [subMaterials, setSubMaterials] = useState([]);

  // Material List
  const materialListOptions = useMemo(() => {
    const materialOptions = Array.isArray(invoiceMaterial)
      ? invoiceMaterial.map(e => ({
          label: `${e.category_title}`,
          value: e.category_id,
        }))
      : [];

    return [...materialOptions];
  }, [invoiceMaterial]);

  const onChangeMaterial = async (e, index) => {
    handleUpdateMaterialData(index, 'mateial_category_id', JSON.stringify(e.value));
    await axios
      .post(
        `${BASE_API_URL}invoice_sub_materials`,
        {
          project_id,
          invoice_id: values.invoice_id,
          category_id: e.value,
        },
        config({ multipart: true }),
      )
      .then(res => {
        setSubMaterials(res?.data?.data);
      })
      .catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Sub Material List
  const subMaterialListOptions = subMaterials?.map(e => ({
    label: `${e.sub_category_title}`,
    value: e.subcat_id,
    unit: e.unit_title,
  }));

  useEffect(() => {
    if (material_sub_category_id) {
      const unitId = subMaterialListOptions?.find(
        id => String(id.value) === String(material_sub_category_id),
      )?.unit;
      handleUpdateMaterialData(index, 'material_unit', unitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [material_sub_category_id]);

  const SubMaterialError =
    errors?.materials?.length && errors?.materials[index]?.material_sub_category_id;

  const validateValues = (value, index, key) => {
    const cleanedValue = isNaN(value) || value < 0 ? 0 : value;

    if (DECIMAL_REGEX.test(cleanedValue)) {
      handleUpdateMaterialData(index, key, value);

      if (key === 'quantity' || key === 'rate') {
        setFieldValue('discount_amount', 0);
        setFieldValue('discount_percent', 0);
      }
    }
  };

  return (
    <tr>
      <td>
        <Select
          closeMenuOnSelect={true}
          defaultValue={
            EDIT ? { label: material.mateial_category, value: material.mateial_category_id } : null
          }
          name={`materials.${index}.mateial_category_id`}
          options={materialListOptions}
          placeholder="Materials"
          styles={{
            container: (base:any) => ({
              ...base,
              width: '100%',
            }),
          }}
          onChange={e => onChangeMaterial(e, index)}
        />
      </td>
      <td>
        <Select
          closeMenuOnSelect={true}
          defaultValue={
            EDIT
              ? { label: material.material_sub_category, value: material.material_sub_category_id }
              : null
          }
          name={`materials.${index}.material_sub_category_id`}
          options={subMaterialListOptions}
          placeholder="Sub Materials"
          styles={{
            container: (base:any) => ({
              ...base,
              width: '100%',
            }),
            control: (base:any) => ({
              ...base,
              borderColor: SubMaterialError ? 'rgba(255, 93, 93)' : 'hsl(0, 0%, 80%)',
            }),
          }}
          onChange={e =>
            handleUpdateMaterialData(index, 'material_sub_category_id', JSON.stringify(e?.value))
          }
        />
      </td>
      <td>
        <input
          disabled
          className="form-control rounded-1"
          name={`materials.${index}.unit`}
          placeholder="Unit"
          type="text"
          value={material_unit}
        />
      </td>
      <td>
        <input
          className="form-control rounded-1"
          name={`materials.${index}.quantity`}
          placeholder="Quantity"
          type="number"
          value={quantity}
          onChange={e => validateValues(e.target.valueAsNumber, index, 'quantity')}
        />
      </td>
      <td>
        <input
          className="form-control mb-2"
          name={`materials.${index}.rate`}
          placeholder="Rate"
          type="number"
          value={rate}
          onBlur={handleBlur}
          onChange={e => validateValues(e.target.valueAsNumber, index, 'rate')}
        />
      </td>
      <td>
        <input
          disabled
          className="form-control text-right"
          placeholder="Total"
          value={total_amount}
        />
      </td>
      <td>
        <IconButton aria-label="delete" color="error" onClick={() => handleRemoveMaterial(index)}>
          <CloseIcon />
        </IconButton>
      </td>
    </tr>
  );
};

export default MaterialRow;
