import { styled, Typography } from '@mui/material';

const Label = styled(Typography)`
  color: #3d3f43;
  font-weight: 400;
  font-size: 18px;
`;
const Value = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #041d36;
`;

const Summary = props => {
  const {
    formikProps,
    handleTotalOtherCharge,
    handleTotalOtherDiscountAmt,
    handleTotalExtraCharge,
  } = props;
  const { values } = formikProps;

  const basicRateBasicAmount = Number(values.basic_rate_basic_amount) || 0;
  const basicRateDiscAmt = Number(values.basic_rate_disc_amt) || 0;
  const gstAmt = Number(values.gst_amt) || 0;
  const regAmt = Number(values.reg_amount) || 0;
  const stampdutyAmt = Number(values.stampduty_amount) || 0;

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Summary</h5>
        <div className="row">
          <div className="col-12">
            <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
              <div className="form-row ">
                <div className="col-2">
                  <Label>Basic amount</Label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-3">
                  <Value>
                    <span> ₹ </span>
                    <span>{basicRateBasicAmount.toFixed(2)}</span>
                    <span style={{ color: '#07CA03' }}> (+)</span>
                  </Value>
                </div>
              </div>
              <div className="form-row ">
                <div className="col-2">
                  <Label>Other charges total</Label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-3">
                  <Value>
                    <span> ₹ </span>
                    <span> {values.calculation_method ? handleTotalOtherCharge() : '0.00'} </span>
                    <span style={{ color: '#07CA03' }}> (+)</span>
                  </Value>
                </div>
              </div>
              <div className="form-row ">
                <div className="col-2">
                  <Label>Total discount</Label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-3">
                  <Value>
                    <span> ₹ </span>
                    <span>
                      {' '}
                      {isNaN(parseFloat(handleTotalOtherDiscountAmt()) + basicRateDiscAmt)
                        ? '0.00'
                        : (parseFloat(handleTotalOtherDiscountAmt()) + basicRateDiscAmt).toFixed(
                            2,
                          )}{' '}
                    </span>
                    <span style={{ color: '#FF2D2D' }}> (-)</span>
                  </Value>
                </div>
              </div>
              <div className="form-row ">
                <div className="col-2">
                  <Label>Goverment taxes total</Label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-3">
                  <Value>
                    <span> ₹ </span>
                    <span>
                      {isNaN(gstAmt + stampdutyAmt + regAmt)
                        ? `0.00`
                        : (gstAmt + stampdutyAmt + regAmt).toFixed(2)}
                    </span>
                    <span style={{ color: '#07CA03' }}> (+)</span>
                  </Value>
                </div>
              </div>
              <div className="form-row ">
                <div className="col-2">
                  <Label>Extra charges</Label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-3">
                  <Value>
                    <span> ₹ </span>
                    <span> {values.calculation_method ? handleTotalExtraCharge() : '0.00'} </span>
                    <span style={{ color: '#07CA03' }}> (+)</span>
                  </Value>
                </div>
              </div>
              <div className="form-row total-background" style={{ padding: '5px 0px' }}>
                <div className="col-2">
                  <Label>Property Final Amount</Label>
                </div>
                <div className="col-1">
                  <label>:</label>
                </div>
                <div className="col-3">
                  <label style={{ fontSize: '24px', fontWeight: 500 }}>
                    <span> ₹ </span>
                    <span>
                      {' '}
                      {values.calculation_method
                        ? isNaN(
                            basicRateBasicAmount +
                              parseFloat(handleTotalOtherCharge()) +
                              gstAmt +
                              stampdutyAmt +
                              regAmt +
                              parseFloat(handleTotalExtraCharge()),
                          )
                          ? (
                              basicRateBasicAmount +
                              parseFloat(handleTotalOtherCharge()) +
                              gstAmt +
                              stampdutyAmt +
                              regAmt +
                              parseFloat(handleTotalExtraCharge())
                            ).toFixed(2)
                          : (
                              basicRateBasicAmount +
                              parseFloat(handleTotalOtherCharge()) +
                              gstAmt +
                              stampdutyAmt +
                              regAmt +
                              parseFloat(handleTotalExtraCharge())
                            ).toFixed(2)
                        : '0.00'}{' '}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
