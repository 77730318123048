import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as visitorService from 'services/construction';
import { DEFAULT_CONSTRUCTION_FILTERS, processError } from 'utils/constant';
import { handleLoading, handleReject } from 'utils/reduxUtils';

import {
  CommonParams,
  IAssigneeOption,
  ICDState,
  ILevelOption,
  IWorkDetails,
  IWorkDetailsParams,
  IWorkOption,
  IWorkParams,
  IWorkProgressData,
  IWorkProgressParams,
} from './constructionInterface';

export const getWorkProgress = createAsyncThunk<IWorkProgressData, IWorkProgressParams>(
  'construction/getWorkProgress',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getWorkProgress(params);
      return res?.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getWorkDetails = createAsyncThunk<IWorkDetails, IWorkDetailsParams>(
  'construction/getWorkDetails',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getWorkDetails(params);
      return res?.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getAssigneeOption = createAsyncThunk<IAssigneeOption[], CommonParams>(
  'construction/getAssigneeOption',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getAssigneeOption(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getLevelOption = createAsyncThunk<ILevelOption[], CommonParams>(
  'construction/getLevelOption',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getLevelOption(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
export const getWorkOption = createAsyncThunk<IWorkOption[], IWorkParams>(
  'construction/getWorkOption',
  async (params, thunkApi) => {
    try {
      const { data: res } = await visitorService.getWorkOption(params);
      return res.data;
    } catch (err) {
      const processedError = processError(err);
      console.log(err);
      return thunkApi.rejectWithValue({ error: processedError });
    }
  },
);
const initialState: ICDState = {
  loading: false,
  getWorkProgressData: {} as IWorkProgressData,
  getWorkDetailsData: {} as IWorkDetails,
  construction_filter: DEFAULT_CONSTRUCTION_FILTERS,
  AssigneeList: [],
  LevelList: [],
  WorkList: [],
};

const constructionDashboardSlice = createSlice({
  name: 'construction',
  initialState,
  reducers: {
    updateConstructionFilters: (state, action) => {
      return {
        ...state,
        construction_filter: action.payload,
      };
    },
  },
  extraReducers: builder => {
    // Work Progress
    builder.addCase(getWorkProgress.rejected, handleReject);
    builder.addCase(getWorkProgress.pending, handleLoading);
    builder.addCase(getWorkProgress.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getWorkProgressData: action?.payload,
      };
    });
    // Work Details
    builder.addCase(getWorkDetails.rejected, handleReject);
    builder.addCase(getWorkDetails.pending, handleLoading);
    builder.addCase(getWorkDetails.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        getWorkDetailsData: action?.payload,
      };
    });
    // get Assignee Options
    builder.addCase(getAssigneeOption.rejected, handleReject);
    builder.addCase(getAssigneeOption.pending, handleLoading);
    builder.addCase(getAssigneeOption.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        AssigneeList: action?.payload,
      };
    });
    // get Level Options
    builder.addCase(getLevelOption.rejected, handleReject);
    builder.addCase(getLevelOption.pending, handleLoading);
    builder.addCase(getLevelOption.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        LevelList: action?.payload,
      };
    });
    // get Work Options
    builder.addCase(getWorkOption.rejected, handleReject);
    builder.addCase(getWorkOption.pending, handleLoading);
    builder.addCase(getWorkOption.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        WorkList: action?.payload,
      };
    });
  },
});

export const { updateConstructionFilters } = constructionDashboardSlice.actions;
export default constructionDashboardSlice.reducer;
