import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Box, Chip, IconButton, styled, Typography } from '@mui/material';
import { useMemo } from 'react';

const PageHeader = styled(AppBar)`
  background: #f6f7fb;
  box-shadow: none;
  color: #041d36;
  align-items: center;
  padding: 10px 20px 10px 40px;
  justify-content: space-between;
  border-bottom: 0.5px solid #d7d3d373;
`;

const BookingHeader = props => {
  const { navigate, formikProps } = props;

  const { errors } = formikProps;

  // handling validations
  const validationErrors = useMemo(() => {
    const parsed = [];

    Object?.values(errors)?.map(error => {
      if (Array.isArray(error)) {
        error.map(err => {
          if (err) {
            Object?.values(err)?.map(v => {
              if (v && !parsed.includes(v)) {
                parsed.push(v);
              }
            });
          }
        });
      } else {
        if (error && !parsed.includes(error)) {
          parsed.push(error);
        }
      }
    });
    return parsed;
  }, [errors]);

  return (
    <PageHeader position="sticky">
      <Box className="d-flex align-item-center justify-content-between w-100">
        <Box className="d-flex align-item-center">
          <IconButton
            style={{ backgroundColor: '#e5eafa', color: '#4872f4' }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography style={{ marginLeft: 12, paddingLeft: 10 }} variant="h5">
            Booking Quotation <Chip color="success" label="Beta" />
          </Typography>
        </Box>
      </Box>

      {/* error box */}
      <Box className="w-100 mt-2 mx-4">
        {validationErrors.length ? (
          <Box
            sx={{
              background: 'rgba(255, 93, 93, 0.1)',
              borderRadius: 2,
              padding: `5px`,
              fontSize: '1rem',
            }}
          >
            <ul>
              {validationErrors?.map((err, index) => {
                return (
                  <li key={index}>
                    <Typography color={'#ff5d5d'} variant="h6">
                      {JSON.parse(JSON.stringify(err))}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Box>
        ) : undefined}
      </Box>
    </PageHeader>
  );
};

export default BookingHeader;
