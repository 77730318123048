const CustomInstallmentRow = props => {
  const { e, i, handleDeleteInstallment } = props;

  return (
    <tr key={`${i}_${e.id}`}>
      <td>{i + 1}</td>
      <td>{e.custom_payment_installment}</td>
      {/* <td>{e.installment_due_date}</td> */}
      <td>{!e.lastRow && <>{e.installment_per}</>}</td>
      {/* <td>
        {!e.lastRow && (
          <input
            className="form-control"
            name={`e.${i}.installment_per`}
            type="number"
            value={e.installment_per}
            onChange={x => {
              handlePaymentScheduleUpdate(i, 'installment_per', x.target.value);
            }}
          />
        )}
      </td> */}
      <td className="text-right">
        {!e.lastRow && (
          <>{e.installment_basic_amt !== 0 ? e.installment_basic_amt : e.installment_basic_amt}</>
        )}
      </td>
      <td className="text-right">
        {e.installment_otherchages_amt !== 0
          ? e.installment_otherchages_amt.toFixed(2)
          : e.installment_otherchages_amt.toFixed(2)}
      </td>
      <td className="text-right">{e.gst !== 0 ? e.gst.toFixed(2) : e.gst.toFixed(2)}</td>
      <td className="text-right">
        {e.installment_extrachages_amt !== 0
          ? e.installment_extrachages_amt.toFixed(2)
          : e.installment_extrachages_amt.toFixed(2)}
      </td>
      <td className="text-right">
        {e.installment_amount !== 0
          ? e.installment_amount.toFixed(2)
          : e.installment_amount.toFixed(2)}
      </td>
      <td>
        {!e.lastRow ? (
          <button
            className="add-comp-btn m-0 acount-act-btn red-common"
            type="button"
            onClick={() => handleDeleteInstallment(i)}
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 6 8"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.498698 6.91667C0.498698 7.375 0.873698 7.75 1.33203 7.75H4.66537C5.1237 7.75 5.4987 7.375 5.4987 6.91667V1.91667H0.498698V6.91667ZM5.91537 0.666667H4.45703L4.04036 0.25H1.95703L1.54036 0.666667H0.0820312V1.5H5.91537V0.666667Z"
                fill="#FF5D5D"
              ></path>
            </svg>
          </button>
        ) : undefined}
      </td>
    </tr>
  );
};

export default CustomInstallmentRow;
