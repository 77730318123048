import { config, instance } from './init';

const params = config({ multipart: false });

export const getVisitorsList = data => {
  return instance.post('/visitors/get_lists', data, params);
};

export const getCustomersList = data => {
  return instance.post('/visitorcustomer/list', data, params);
};

export const addCustomer = data => {
  return instance.post('/visitors/add', data, params);
};

export const getUnitInfo = data => {
  return instance.post('/areaSheet/booking_unitSheet_tower_list', data, params);
};

export const getAreaInfo = data => {
  return instance.post('/getUnitAreasheetInfo', data, params);
};

export const getUnitParkingInfo = data => {
  return instance.post('/parking/list_parking_design', data, params);
};

export const addBooking = data => {
  return instance.post('/new_booking_form', data, config({ multipart: true }));
};

export const getOtherCharges = data => {
  return instance.post('/unit_bookingform_other_charges', data, params);
};
export const getOtherExtraCharges = data => {
  return instance.post('/unit_bookingform_extra_other_charges', data, params);
};

export const getTermsnContions = data => {
  return instance.post('/booking_terms_conditions', data, params);
};

export const getInstallmentOptions = data => {
  return instance.post('/payment_scheduled_master_list', data, params);
};

export const getInstallmentData = data => {
  return instance.post('/payment_scheduled_details_master', data, params);
};

export const getCLPInstallmentOptions = data => {
  return instance.post('/get_payment_installment_list', data, params);
};

export const getCLPInstallmentData = data => {
  return instance.post('/get_payment_installment_details', data, params);
};

export const getBankList = () => {
  return instance.get('/get_banks', params);
};

export const updateFormFillingStatus = data => {
  return instance.post('/update_unit_filling', data, params);
};

export const getProjectUnitStatus = data => {
  return instance.post('/get_locked_units', data, params);
};

export const getBrokerList = data => {
  return instance.post('/list_brokers', data, params);
};

export const addBroker = data => {
  return instance.post('/add_broker', data, params);
};
export const parkingList = data => {
  return instance.post('/get_parking_lists', data, params);
};

//booking approval and preview
export const getBrokerDetail = data => {
  return instance.post('/broker_details', data, params);
};

export const getVisitorDetail = data => {
  return instance.post('/visitors/details', data, params);
};

export const getApprovalUnitDetails = data => {
  return instance.post('/unit_booking_form_list', data, params);
};

export const getBookingApprovalList = data => {
  return instance.post('/booking_all_form_list', data, params);
};

export const updateBookingStatus = data => {
  return instance.post('/booking_form_apporved_reject', data, params);
};

export const getBookingFormOwnerFlag = data => {
  return instance.post('/booking_form_settings/get_booking_ownership_status', data, params);
};

export const sendBookingFormOTP = data => {
  return instance.post('/bookingform2_send_otp', data, params);
};

export const clpPercentageSelection = data => {
  return instance.post('/clp_bank_listing', data, params);
};

export const updateUnitStatusToAvailable = data => {
  return instance.post('/status_filling_to_available', data, params);
};
export const countryCode = data => {
  return instance.post('/countries/code', data, params);
};
export const getOwnershipInputField = data => {
  return instance.post('/get_booking_ownership_input_settings', data, params);
};

// Sales Dashboard
export const getLeadFlow = data => {
  return instance.post('/sales/total_leads', data, params);
};

export const getFollowUpCount = data => {
  return instance.post('/sales/followups_count', data, params);
};
export const getFollowUpList = data => {
  return instance.post('/sales/followups_list', data, params);
};

export const getSalesPipeline = data => {
  return instance.post('/sales/sales_pipeline', data, params);
};

export const getLeadsByPriority = data => {
  return instance.post('/sales/leads_percent_by_prority', data, params);
};

export const getLeadsBySource = data => {
  return instance.post('/sales/leads_percent_by_source', data, params);
};

export const getTotalProperties = data => {
  return instance.post('/sales/total_properties', data, params);
};

export const getTowers = data => {
  return instance.post('/sales/towers', data, params);
};

export const getSoldUnitsByMonths = data => {
  return instance.post('/sales/sold_units', data, params);
};

export const getSoldUnitsCountBySource = data => {
  return instance.post('/sales/sold_units_count_by_source', data, params);
};

export const getPropertySoldByCategory = data => {
  return instance.post('/salesdashboard/charts', data, params);
};
export const getSalesInquiryFlow = data => {
  return instance.post('/sales/inquiry_flow', data, params);
};
export const getVisitorYearOption = data => {
  return instance.post('/sales/visitors_last_year_array', data, params);
};
export const getEmployeeWiseSales = data => {
  return instance.post('/sales/employee_wise_sales', data, params);
};

export const getInquiryOwnerships = data => {
  return instance.post('/visitors_details_for_ownership', data, params);
};
export const getUserRoleOption = data => {
  return instance.post('sales/user_dropdown_role_wise', data, params);
};
